import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';
import { SECURITY_QUESTION_LIST, VALIDE_USER } from '../../api/userAPI';
import { FIRST_TIME_LOGIN_RESET } from '../../api/adminTab';
import { PASSWORD_REGEX } from '../../constants/Regexs';
import '../User/signin.css';
import '../forgotPassword/ForgotPassword.css';
import SecurityQuestionField from '../SecurityQuestionField';
import PasswordField from '../PasswordField';
import CustomTextField from '../CustomTextField';

const ResetPassword = () => {
  const { reset } = useAuth();
  const navigate = useNavigate();
  const { headers } = useAuth();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(null);
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const [securitryQuestions, setSecuritryQuestions] = useState("");
  const [firstSecuritryQuestion, setFirstSecuritryQuestion] = useState(null);
  const [secondSecuritryQuestion, setSecondSecuritryQuestion] = useState(null);

  const [firstSecuritryAnswer, setFirstSecuritryAnswer] = useState('');
  const [secondSecuritryAnswer, setSecondSecuritryAnswer] = useState('');
  const [userQueAndAns, setUserQueAndAns] = useState(false);
  const [error, setError] = useState('');

  const handleResetPassword = () => {
    setError("");
    setConfirmPasswordError("");
    setConfirmPasswordError("");
    if(oldPassword !== ''){
      if (oldPassword === newPassword) {
        setNewPasswordError('New password should not be the same as old password.');
      } else if (newPassword !== confirmPassword) {
        setConfirmPasswordError("New password and confirm password doesn't match.");
      } else {
         const payload = {
            confirmPassword: confirmPassword,
            newPassword: newPassword,
            oldPassword: oldPassword,
            firstSecurityQuestionID:firstSecuritryQuestion,
            firstSecurityQuestionAnswer:firstSecuritryAnswer,
            secondSecurityQuestionID:secondSecuritryQuestion,
            secondSecurityQuestionAnswer:secondSecuritryAnswer
          };

        axios.post(FIRST_TIME_LOGIN_RESET, payload, headers)
          .then((response) => {
            if (response.data.success) {
              window.flash(response.data.message, 'success');
              reset();
              navigate('/');
            } else {
              setError(response.data.message);
              console.error(response.data.message, 'error');
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 500){
              reset();
              navigate('/');
              window.flash("Session expired","error");
            } else{
              console.error('Error while Resect password:', error);
            }
          });
      }
    } else {
      setOldPasswordError('Please enter Old Password.')
    }
  };

  const isButtonEnabled = () => {
    if (userQueAndAns){
      return (
          PASSWORD_REGEX.test(oldPassword) &&
          PASSWORD_REGEX.test(newPassword) &&
          confirmPassword !== '' &&
          PASSWORD_REGEX.test(confirmPassword) &&
          firstSecuritryQuestion !== '' &&
          firstSecuritryQuestion !== undefined &&
          firstSecuritryQuestion !== null &&
          firstSecuritryAnswer.trim() !== '' && 
          secondSecuritryQuestion !== '' &&
          secondSecuritryQuestion !== undefined &&
          secondSecuritryQuestion !== null &&
          secondSecuritryAnswer.trim() !== '' 
      );
    } else {
      return(
        PASSWORD_REGEX.test(oldPassword) &&
        PASSWORD_REGEX.test(newPassword) &&
        confirmPassword !== ''
      )
    }
  };

  const handleOldPassword = (event) => {
    setError("");
    setOldPassword(event);
    if (!PASSWORD_REGEX.test(event)) {
      setOldPasswordError('Not a valid password.');
    } else {
      setOldPasswordError('');
    }
  };

  const handleNewPassword = (event) => {
    setError("");
    setNewPassword(event);
    if (PASSWORD_REGEX.test(event)) {
      if (event === newPassword) {
        setNewPasswordError('New password should not be the same as old password.');
      } else {
        setNewPasswordError('');
      }
    } else {
      setNewPasswordError('Password must be 10+ characters and include at least one lowercase letter, uppercase letter, number and a special character(!@#$%^&*-_+=).');
    } 
  };

  const handleConfirmPassword = (event) => {
    setError("");
    setConfirmPassword(event);
    if (PASSWORD_REGEX.test(event)) {
        setConfirmPasswordError('');
    } else {
      setConfirmPasswordError('Password must be 10+ characters and include at least one lowercase letter, uppercase letter, number and a special character(!@#$%^&*-_+=).');
    } 
  };

  const toggleShowPassword = (field) => {
    switch (field) {
      case 'oldPassword':
        setShowOldPassword(!showOldPassword);
        break;
      case 'newPassword':
        setShowNewPassword(!showNewPassword);
        break;
      case 'confirmPassword':
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  function getCookie(name) {
    const cookies = document.cookie.split('; '); // Split cookies by '; ' (note the space)
    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('='); 
      if (cookieName === name) {
        return cookieValue; 
      }
    }
    return null; 
}
  useEffect(() => {
    getSecurityQueAndAns();
  },[]);

  const getSecurityQueAndAns = () => {
    let config = {
      headers: {
        Authorization :"Bearer "+ getCookie('token'),
      }
    }    
    axios.get(VALIDE_USER, config)
    .then((response) => {
      if (response.data.success) {
        setUserQueAndAns(response.data.body.enableQuestions);
        if(response.data.body.enableQuestions)
        securityQuestions();
      }
    })
    .catch((error) => {
      console.error('Error while reset password:', error);
    });
  }
  
  const securityQuestions = () => {
    axios.get(SECURITY_QUESTION_LIST)
    .then((response) => {
      if (response.data.success) {
        setSecuritryQuestions(response.data.body);
      } else {
        setSecuritryQuestions(null);
        console.error(response.data.message, 'error');
      }
    })
    .catch((error) => {
      console.error('Error while reset password:', error);
    });
  }

  const handleFirstSecurityQuestionChange = (event) => {
    setError("");
    setFirstSecuritryQuestion(event.target.value);
  };

  const handleSecondSecurityQuestionChange = (event) => {
    setError("");
    setSecondSecuritryQuestion(event.target.value);
  }

  const handleFirstSecurityAnswerChange = (event) => {
    setError("");
    setFirstSecuritryAnswer(event.target.value);
  };

  const handleSecondSecurityAnswerChange = (event) => {
    setError("");
    setSecondSecuritryAnswer(event.target.value);
  }

  return (
    <div>
      <Container component="main">
        <Box
         className='outerbox'
        >
          <Typography
            component="h1"
            variant="h5"
          >
            Reset Password
          </Typography>
          <Box
            component="form"
            noValidate
            className='innerbox'
          >
          <PasswordField
            label="Old Password"
            value={oldPassword}
            onChange={(e) => handleOldPassword(e.target.value)}
            showPassword={showOldPassword}
            toggleShowPassword={() => toggleShowPassword('oldPassword')}
            error={oldPasswordError}
            helperText={oldPasswordError}
          />
          <PasswordField
            label="New Password"
            value={newPassword}
            onChange={(e) => handleNewPassword(e.target.value)}
            showPassword={showNewPassword}
            toggleShowPassword={() => toggleShowPassword('newPassword')}
            error={newPasswordError}
            helperText={newPasswordError}
          />
          <PasswordField
            label="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => handleConfirmPassword(e.target.value)}
            showPassword={showConfirmPassword}
            toggleShowPassword={() => toggleShowPassword('confirmPassword')}
            error={confirmPasswordError}
            helperText={confirmPasswordError}
          />
            {userQueAndAns && (
              <>
                <SecurityQuestionField
                  label="First Security Question"
                  value={firstSecuritryQuestion}
                  onChange={handleFirstSecurityQuestionChange}
                  options={securitryQuestions}
                />
                 < CustomTextField 
                    id="security-answer"
                    label="First Security Answer"
                    name="First Security Answer"
                    autoComplete="off"
                    value={firstSecuritryAnswer}
                    onChange={handleFirstSecurityAnswerChange}
                  /> 
                <SecurityQuestionField
                  label="Second Security Question"
                  value={secondSecuritryQuestion}
                  onChange={handleSecondSecurityQuestionChange}
                  options={securitryQuestions}
                />
                 < CustomTextField 
                    id="Second_Security_Answer"
                    label="Second Security Answer"
                    name="Second Security Answer"
                    autoComplete="off"
                    value={secondSecuritryAnswer}
                    onChange={handleSecondSecurityAnswerChange}
                  /> 
              </>)
            }
            {error != "" &&
              <h6 id="errorMessage">{error}</h6>
            }
            <Button
              variant="contained"
              color="primary"
              onClick={handleResetPassword}
              disabled={!isButtonEnabled()}
              fullWidth
              sx={{ mt: 1, width: '70%' }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default ResetPassword;