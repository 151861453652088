const URL = process.env.REACT_APP_COAT_SERVER || '';
const SEARCH_URI = `${URL}api/search/`;

export const INTERNAL_PLAYER = `${SEARCH_URI}internalplayer?query=`;
export const EXTERNAL_PLAYER = `${SEARCH_URI}partneraccount?query=`;
export const GAME_SESSION_ID = `${SEARCH_URI}gamesession?query=`;
export const WALLET_SESSION_ID = `${SEARCH_URI}walletsession?query=`;
export const WALLET_WAGER_ROUND_ID = `${SEARCH_URI}walletwagerround?query=`;
export const GAME_WAGER_ROUND_ID = `${SEARCH_URI}gamewagerround?query=`;
export const ALL_SEARCH = `${SEARCH_URI}all`;
