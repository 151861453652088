import React from 'react';
import './ByPlayer.css';

const ByPlayer = ({ props }) => {
  const { isButtonEnabled, externalPlayerId, handleFindGame, updateState } = props;

  const onDataChange = (event) => {
    updateState(event.target.value);
  }
  return (
    <div>
      <input
        type="text"
        className="form-control"
        placeholder="External PlayerId"
        value={externalPlayerId}
        onChange={(event) => onDataChange(event)}
      />
      <button className="btn btn-primary" 
        id="FindGameByPlayerIdButton" 
        disabled={ !isButtonEnabled() } 
        onClick={ handleFindGame }>
          Find Game
      </button>
    </div>
  );
};

export default ByPlayer;
