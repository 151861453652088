import React, { useState, useEffect } from "react";
import PlayerIdSearchList from "../Search/PlayerIdSearchList";
import { CASINO_OPEN_SESSION } from '../../api/casinoSession';
import LoadingSpinner from './../helper/spinner/LoadingSpinner';
import Rounds from "./Rounds";
import {CASIO_OPEN_ROUNDS, CASINO_CLOSE_WALLET_SESSION} 
from  './../../api/casinoSession';
import CloseConfirmation from "./CloseConfirmation";
import NoDataModal from "./NoDataModal";
import { useAuth } from "../../hooks/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const OpenCasino = (props) => {
  const navigate = useNavigate();
  const { headers, reset } = useAuth();
  const { selectedPlayerId } = props;
  const [isLoading, setIsLoading] = useState(true);
  const[ openSessions, setOpenSessions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const[walletSessionId, setWalletSessionId] = useState('');
  const [rounds, setRounds] = useState([]);

  const [closeMessage, setCloseMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  
  //Use these state when we have session but no rounds present
  const [displayNoDataModal, setDisplayNoDataModal] = useState(false);
  const [NoDataMessage, setNoDataMessage] = useState('');

  const {roles } = useAuth();
  const [clickable, setClickable] = useState(false);

  //Onclick wallet session check any wager rounds are present
  const getWagerRounds = async(walletSessionId) => {
    setWalletSessionId(walletSessionId);
    let query = `sessionId=${walletSessionId}`;
    await fetch(CASIO_OPEN_ROUNDS + query, headers)
      .then(response => response.json())
      .then(data => {
        if(data.status && data.status === 500) {
          setErrorMessage('Session Expired');
          reset();
          window.flash('Session Expired', 'error');
          navigate('/')
        }
        if(data.body.length === 0) {
          showNoDataModal();
        }else {
          setRounds(data.body);
        }
      })
      .catch(err => console.log(err));
  }

  //On change of player select use effect gets called
  useEffect(() => {
  
  if(roles.ROLE_SUPER_ADMIN || roles.ROLE_ADMIN) {
    setClickable(true);
  }
  const openSessions = async() => {
    let query = `?playerId=${selectedPlayerId}`;
    await fetch(CASINO_OPEN_SESSION + query, headers)
      .then(response => response.json())
      .then(data => {
        if(data.status && data.status === 500) {
          setErrorMessage('Session Expired');
          reset();
          window.flash('Session Expired', 'error');
          navigate('/')
        }

        if(data.body == null) {
          setOpenSessions([]);
          setErrorMessage(data.message);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          let result = data.body.map(function(session) {
            session.close = 'Close';
            return session;
          })
          setOpenSessions(result);
        }
      })
      .catch(err => console.log(err));
  }
  openSessions();
  },[selectedPlayerId])

  //This Modal will show for confirmation to close selected wallet session
  const showCloseModal = (walletSessionId) =>{
    setWalletSessionId(walletSessionId);
    setCloseMessage(`Are you sure you want to close wallet 
    session ${walletSessionId} `);
    setDisplayConfirmationModal(true);
  }

  //This modal popup gets called when there is active session but not wager rounds
  const showNoDataModal = () => {
    setNoDataMessage("There are no wager rounds present for given wallet session " + walletSessionId);
    setDisplayNoDataModal(true);
  }

  const renderOpenSessions = (
    <>
    <div>
        <span>
          <h5>Selected Player ID - {selectedPlayerId}</h5>
        </span>
      </div>
      <PlayerIdSearchList 
      players={openSessions} 
      getWalletSessionId={getWagerRounds}
      showCloseModal={showCloseModal}
      clickable={clickable}
      />
    </>
    
  );

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  //hide No data modal
  const hideNoDataModal = () => {
    setDisplayNoDataModal(false);
  };

  //Close wallet session
  const submitClose = (walletSessionId)=> {
    axios.post(CASINO_CLOSE_WALLET_SESSION + walletSessionId, null, headers)
      .then((response)=> {
        if(!response.data.success) {
          window.flash(response.data.message,"error");
        }else{
          window.flash(response.data.message,"success");
        }
        hideConfirmationModal();
    })
    .catch((error) => {
      if(error.response.status === 500){
        reset();
        navigate('/');
        window.flash("Session expired","error");
        console.log('Error fetching user list:', error);
      } 
    });
  }

  return (
    <>
      {isLoading ? <LoadingSpinner /> : renderOpenSessions}
      
      <NoDataModal showModal={displayNoDataModal} hideModal={hideNoDataModal} 
      message={NoDataMessage} />
      
      <CloseConfirmation showModal={displayConfirmationModal} 
      confirmModal={submitClose} hideModal={hideConfirmationModal} 
      id={walletSessionId} message={closeMessage} />

      {errorMessage && (<p className="error"> {"There are no open sessions !!"} </p>)}
      
      <Rounds walletSessionId = {walletSessionId} rounds ={rounds} />
    </>
  )
}

export default OpenCasino;