import React, { useEffect, useState } from 'react';
import Bus from './Bus';

import './flash.css';

export const Flash = () => {
  let [visibility, setVisibility] = useState(false);
  let [message, setMessage] = useState('');
  let [type, setType] = useState('');

    useEffect(() => {
        Bus.addListener('flash', ({message, type}) => {
            setVisibility(true);
            setMessage(message);
            setType(type);
            setTimeout(() => {
                setVisibility(false);
            }, 4000);
        });
    }, []);

    useEffect(() => {
        if(document.querySelector('.flash-close') !== null) {
            document.querySelector('.flash-close').addEventListener('click', 
            () => setVisibility(false));
        }
    })

    return (
        visibility && <div className={`flash-alert flash-alert-${type}`}>
                <span className="flash-close"><strong>X</strong></span>
                <p>{message}</p>
            </div>
    )
}
