import React, { useState, useEffect } from 'react';
import axios from 'axios';
import RadioButtons from './RadioButtons';
import AllGames from './AllGames';
import ByPlayer from './ByPlayer';
import Table from './Table';
import { GAME_LIST, PARTNERS_LIST, STUCK_GAME_WAGERS, STUCK_GAME_API} from "../../../api/stuckGameHandler";
import { useAuth } from '../../../hooks/AuthContext';
import { useNavigate } from "react-router-dom";
import LoadingSpinner from '../../helper/spinner/LoadingSpinner';
import './StuckGameHandler.css'

const StuckGameHandler = () => {
  const navigate = useNavigate();
  const [game, setgame] = useState([]);
  const [selectedgame, setSelectedgame] = useState(null);
  const [partner, setPartner] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [externalPlayerId, setExternalPlayerId] = useState(null);
  const [findGameResponse, setFindGameResponse] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectPayload, setPayload] = useState([]);
  const [selectedOpenWager, setSelectedOpenWager] = useState([])
  const [allGames, setAllGames] = useState(false);
  const [byPlayer, setByPlayer] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [closeGameType, setCloseGameType] = useState('');
  const [gameNotFoundMessage, setGameNotFoundMessage] = useState(false);
  const {headers, reset} = useAuth();
  const [dissableButton, setDissableButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    axios.get(GAME_LIST, headers)
      .then((response) => {
        setgame(response.data.body);
      })
      .catch((error) => {
        if(error.response && error.response.status === 500){
          reset();
          navigate('/');
          window.flash("Session expired","error");
        } else{
          console.log('Error fetching games list:', error);
        }
      });

    axios.get(PARTNERS_LIST, headers)
      .then((response) => {
        setPartner(response.data.body);
      })
      .catch((error) => {
        if(error.response && error.response.status === 500){
          reset();
          navigate('/');
          window.flash("Session expired","error");
        } else{
          console.log('Error fetching partners list:', error);
        }
      });
  }, []); 

  useEffect(() => {
    changeSelectAll();
  }, [selectedOpenWager]);

  const isButtonEnabled = () => ((externalPlayerId !== null && externalPlayerId !== "") && (!dissableButton));

  const findGameButtonEnabled = () => (dissableButton);
  
  const handleFindGame = () => {
    setIsLoading(true);
    setDissableButton(true);
    const payload = {
      playerId: externalPlayerId
    }
    if((payload.playerId === "") || (payload.playerId === null)){
      alert("Please specify the external player ID")
    }else{
      setPayload(payload);
      stuckGameCall(payload);
      setSelectedOpenWager([]);
      setSelectAll(false);
    }
  }

  const handleIncompleteGame = () => {
    setIsLoading(true);
    setDissableButton(true);
    const payload = {
      gameId: selectedgame,
      partnerId: selectedPartner,
      fromAge: startDate,
      toAge: endDate*60,
    }
    const date = startDate-endDate*60;
    if((date)>7200){
      alert("The selected time span is more than 5 days.")
      setDissableButton(false);
      setIsLoading(false);
    }else{
      setPayload(payload);
      stuckGameCall(payload);
    }
  };

  const clearInputValues = () => {
    setSelectedEndDate('');
    setSelectedStartDate('');
    setSelectedPartner('');
    setSelectedgame('');
    setStartDate(null);
    setEndDate(null);
    setSelectedOpenWager([]);
    setSelectAll(false);
    setGameNotFoundMessage(false);
    setExternalPlayerId(null);
  }

  const stuckGameCall = (payload) => {
    axios.post(STUCK_GAME_WAGERS, payload, headers)
    .then((response) => {
      if (response.data.body === null){
        window.flash(response.data.message,"error");
        setGameNotFoundMessage(true)
        setFindGameResponse('');
      } else {
        window.flash(response.data.message,"success");
        setFindGameResponse(response.data.body);
        setGameNotFoundMessage(false)
      }
      setDissableButton(false);
      setIsLoading(false);
    })
    .catch((error) => {
      if (error.response && error.response.status === 500){
        reset();
        navigate('/');
        window.flash("Session expired","error");
      } else {
        console.error('Error making API call:', error);
      }
      setDissableButton(false);
      setIsLoading(false);
    });
  }

  const handleStartDateChange = (event) => {
    const selectedDate = event.target.value;
    setSelectedStartDate(selectedDate);
    const selectedDateString = formatDate(selectedDate);
    const dateAsIntegerValue = 
    Math.round(Math.abs(new Date().getTime() - new Date(selectedDateString)
      .getTime()) / 3600000)*60; 
    setStartDate(dateAsIntegerValue);
  }

  const handleEndDateChange = (event) => {
    const selectedDate = event.target.value;
    setSelectedEndDate(selectedDate);
    const selectedDateString = formatDate(selectedDate);
    const dateAsIntegerValue = 
    Math.round(Math.abs(new Date().getTime() - new Date(selectedDateString)
      .getTime()) / 3600000)-24;
    setEndDate(dateAsIntegerValue);
  }

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;
  }

  const confirmationBox = (event) => {
    setCloseGameType(event.target.value);
    setIsModalOpen(true);
  }

  const closeConfirmationBox = () => {
    setIsModalOpen(false);
  }

  const closemultiplegames = () => {
    setIsModalOpen(false);
    let inputRestObj = [];
    let cancelWager = false;
    let URLEndPoint = "closeGame";
    if(closeGameType === "ForceClose"){
      URLEndPoint = "forceCloseGame";
      cancelWager = true;
    }
    for (const element of selectedOpenWager) {
        let obj = {
            "sessionId": element.sessionReference,
            "wagerId": element.wagerReference,
            "forceCancelWager": cancelWager
        };
        inputRestObj.push(obj);
    }
    closemultiplegamesCall(inputRestObj, URLEndPoint);
  }

  const closemultiplegamesCall = (payload, URLEndPoint) => {
    if(payload.length === 0)
    {
      alert("Please select row.")
    }
    axios.post(STUCK_GAME_API + URLEndPoint, payload, headers)
      .then((response) => {
        if(response.data.success){
          window.flash(response.data.message,"success");
          setTimeout(() => {
            stuckGameCall(selectPayload);
          }, 30000);
        }
      })
      .catch((error) => {
        if(error.response && error.response.status === 500){
          reset();
          navigate('/');
          window.flash("Session expired","error");
        }else{
          console.error('Error making API call:', error);
        }
      });
  }; 

  const handleRadioChange = (event) => {
    const radioButtonValue = event.target.value;
    setFindGameResponse('');
    if (radioButtonValue === 'AllGames') {
      setAllGames(true);
      setByPlayer(false);
    } else if (radioButtonValue === 'ByPlayer') {
      setAllGames(false);
      setByPlayer(true);
    }
    clearInputValues();
  };

  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedOpenWager(findGameResponse.map((row) => row));
    } else {
      setSelectedOpenWager([]);
    }
  };  

  const handleRowSelect = (event, row) => {
    if (event.target.checked) {
      setSelectedOpenWager((openWager) => [...openWager, row]);
    } else {
      setSelectAll(false);
      setSelectedOpenWager((openWager) =>
        openWager.filter((rowValue) => rowValue.glssessionId !== row.glssessionId)
      );
    }
  };

  const changeSelectAll = () => {
    if(selectedOpenWager.length !== 0){
      if(findGameResponse.length === selectedOpenWager.length){
        setSelectAll(true);
      }
    }
  }

  const updateState = (externalPlayerId) =>  {
    setExternalPlayerId(externalPlayerId);
    isButtonEnabled();
  }

  return (
    <div>
      <div className="main-div">
      <div className="alert alert-light">
        <RadioButtons props = {{handleRadioChange, allGames, byPlayer }} />
        {allGames && (
          <AllGames 
            props = {{ 
            game, partner, selectedgame, setSelectedgame, 
            selectedPartner, setSelectedPartner, 
            selectedStartDate, handleStartDateChange, selectedEndDate, 
            handleEndDateChange, handleIncompleteGame, findGameButtonEnabled
          }}/>
        )}
        {byPlayer && (
          <ByPlayer props={{ updateState, handleFindGame, isButtonEnabled}}/>
        )}
        </div>
      </div>
      {findGameResponse && (
        <Table props = {{
          findGameResponse, selectedOpenWager, handleSelectAll, isModalOpen, closeGameType,
          handleRowSelect, closemultiplegames, formatDate, selectAll, confirmationBox,
          closeConfirmationBox }} 
        />
      )}
      {gameNotFoundMessage && (
        <h5> No incomplete games found.</h5>
      )}
      {isLoading && (<LoadingSpinner/>)}
    </div>
  );
};

export default StuckGameHandler;
