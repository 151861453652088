const URL = process.env.REACT_APP_COAT_SERVER || '';

const BACKOFFICE_URI = `${URL}api/`;
export const USER_LIST = `${BACKOFFICE_URI}admin/users`;
export const CREATE_USER = `${BACKOFFICE_URI}admin/adduser`;
export const ROLES = `${BACKOFFICE_URI}userrole/roles`;
export const RESET_PASSWORD = `${BACKOFFICE_URI}admin/update/password`;
export const UPDATE_USER = `${BACKOFFICE_URI}admin/update`;
export const DELETE_USER = `${BACKOFFICE_URI}admin/delete?username=`;
export const FIRST_TIME_LOGIN_RESET = `${BACKOFFICE_URI}admin/reset/password`;
