import React from 'react';
import './RadioButtons.css';

const RadioButtons = ({ props }) => {
  return (
    <div className="alert alert-light" id='stuck-game-search-button'>
      <h4 className='stuckgame-titel-div'>Find Incomplete Games</h4>
      <div className="formRow">
        <div className="formCheck">
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            value="AllGames"
            onChange={props.handleRadioChange}
            checked={props.allGames}
          />
          <label>By Game</label>
        </div>
        <div className="formCheck">
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            value="ByPlayer"
            onChange={props.handleRadioChange}
            checked={props.byPlayer}
          />
          <label>By Player</label>
        </div>
      </div>
    </div>
  );
};

export default RadioButtons;
