import React, { useState } from 'react';
import './RadioButton.css'

function RadioButton(props) {
  const [value, setValue] = useState('byPlayerId');
  const { optionCallback } = props;

  const onOptionChange = (event) => {
    setValue(event.target.value);
    optionCallback(event.target.value);
  };

  return (
    <div className="alert alert-light" id='search-button'>
      <h5 className='titel-div'>Search by</h5>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="byPlayerId1"
          value="byPlayerId"
          checked={value === 'byPlayerId'}
          onChange={onOptionChange}
        />
        <label className="form-check-label" htmlFor="byPlayerId1">Player Id</label>
      </div>

      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="bySessionId1"
          value="bySessionId"
          checked={value === 'bySessionId'}
          onChange={onOptionChange}
        />
        <label className="form-check-label" htmlFor="bySessionId1">Session Id</label>
      </div>
      <div className="form-check form-check-inline">

        <input
          className="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="byRoundId1"
          value="byRoundId"
          checked={value === 'byRoundId'}
          onChange={onOptionChange}
        />
        <label className="form-check-label" htmlFor="byRoundId1">Round Id</label>
      </div>

      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="all1"
          value="byAll"
          checked={value === 'byAll'}
          onChange={onOptionChange}
        />
        <label className="form-check-label" htmlFor="byAll1">All</label>
      </div>
    </div>
  );
}

export default RadioButton;
