import React from "react";
import RoundModal from "./RoundModal";

function Rounds(props) {
  const { rounds } = props;
  return(
    <RoundModal 
    rounds={rounds} 
    />
  )
}

export default Rounds;