import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';
import { PASSWORD_REGEX, EMAIL_REGEX } from '../../constants/Regexs';
import { USER_SECURITY_QUE_AND_ANS, FORGOT_PASSWORD_URL } from '../../api/userAPI';
import '../User/signin.css';
import './ForgotPassword.css';
import SecurityQuestionField from '../SecurityQuestionField';
import PasswordField from '../PasswordField';
import CustomTextField from '../CustomTextField';

const ForgotPassword = () => {
  const { reset } = useAuth();
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const [userName, setUserName] = useState('');
  const [securitryQuestionList, setSecuritryQuestionsList] = useState('');
  const [securitryQuestion, setSecuritryQuestion] = useState(0);
  const [error, setError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [securitryAnswer, setSecuritryAnswer] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
  const handleResetPassword = () => {
    setConfirmPasswordError();
    setNewPasswordError();
    if (newPassword !== confirmPassword) {
      setNewPasswordError("New Password and Confirm Password doesn't match.");
    } else {
      const payload = {
        userName:userName,
        confirmPassword: confirmPassword,
        newPassword: newPassword,
        securityQuestionAnswer:securitryAnswer,
        securityQuestionID:securitryQuestion
      };

      axios.post(FORGOT_PASSWORD_URL, payload)
        .then((response) => {
          if (response.data.success) {
            window.flash(response.data.message, 'success');
            reset();
            navigate('/');
          } else {
            setError(response.data.message);
          }
        })
        .catch((error) => {
            console.error('Error while Forgot password:', error);
        });
    }
  };

  const isButtonEnabled = () => {
      return (
        PASSWORD_REGEX.test(newPassword) &&
        confirmPassword !== '' &&
        userName !== '' &&
        securitryQuestion !== 0 &&
        securitryQuestion !== undefined &&
        securitryAnswer.trim() !== ''
      );
  };

  useEffect(() => {
    if (EMAIL_REGEX.test(userName)) {
      axios.post(`${USER_SECURITY_QUE_AND_ANS}?userName=${userName}`)
      .then((response) => {
        if (response.data.success && response.data.body.length > 0) {
          setSecuritryQuestionsList(response.data.body);
          setErrorMessage('');
        } else {
          setErrorMessage(response.data.message);
          console.error(response.data.message, 'error');
        }
      })
      .catch((error) => {
        console.error('Error while Resect password:', error);
      });
    }
  }, [userName]);

  const handleNewPassword = (event) => {
    setError("");
    setNewPassword(event);
    if (PASSWORD_REGEX.test(event)) {
      if (event === newPassword) {
        setNewPasswordError('New Password should not be the same as Old Password.');
      } else {
        setNewPasswordError('');
      }
    } else {
      setNewPasswordError('Password must be 10+ characters and include at least one lowercase letter, uppercase letter, number and a special character(!@#$%^&*-_+=).');
    } 
  };

  const handleConfirmPassword = (event) => {
    setError("");
    setConfirmPassword(event);
    if (PASSWORD_REGEX.test(event)) {
        setConfirmPasswordError('');
    } else {
      setConfirmPasswordError('Password must be 10+ characters and include at least one lowercase letter, uppercase letter, number and a special character(!@#$%^&*-_+=).');
    } 
  };

  const toggleShowPassword = (field) => {
    switch (field) {
      case 'newPassword':
        setShowNewPassword(!showNewPassword);
        break;
      case 'confirmPassword':
        setShowConfirmPassword(!showConfirmPassword);
        break;
    }
  };
  
  const handleSecurityQuestionChange = (event) => {
    setError("");
    setSecuritryQuestion(event);
  };

  const handleSecurityAnswerChange = (event) => {
    setError("");
    setSecuritryAnswer(event.target.value);
  };

  const handleUserNameChange = (event) => {
    setSecuritryQuestionsList('');
    if(EMAIL_REGEX.test(event)) {
      setError("");
    } else {
      setErrorMessage('Please enter a valid user name.');
    }
    setUserName(event);
  };

  return (
        <div>
            <Container component="main">
                <Box
                  className='outerbox'
                >
                <Typography
                  component="h1"
                  variant="h5"
                >
                  Forgot Password
                </Typography>
                <Box
                  component="form"
                  Validate
                  className='innerbox'
                >
                  <CustomTextField 
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    value={userName}
                    error={errorMessage}
                    helperText={errorMessage}
                    autoFocus
                    onChange={(e) => handleUserNameChange(e.target.value)}
                  />
                  <SecurityQuestionField
                    label="Security Question"
                    value={securitryQuestion}
                    onChange={(e) => handleSecurityQuestionChange(e.target.value)}
                    options={securitryQuestionList}
                  />
                  < CustomTextField 
                    id="security-answer"
                    label="Security Answer"
                    name="SecurityAnswer"
                    autoComplete="off"
                    value={securitryAnswer}
                    onChange={handleSecurityAnswerChange}
                  />            
                  <PasswordField
                    label="New Password"
                    value={newPassword}
                    onChange={(e) => handleNewPassword(e.target.value)}
                    showPassword={showNewPassword}
                    toggleShowPassword={() => toggleShowPassword('newPassword')}
                    error={newPasswordError}
                    helperText={newPasswordError}
                  />        
                  <PasswordField
                    label="Confirm New Password"
                    value={confirmPassword}
                    onChange={(e) => handleConfirmPassword(e.target.value)}
                    showPassword={showConfirmPassword}
                    toggleShowPassword={() => toggleShowPassword('confirmPassword')}
                    error={confirmPasswordError}
                    helperText={confirmPasswordError}
                  />
                    {error != "" &&
                      <h6 id="errorMessage">{error}</h6>
                    }
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleResetPassword}
                    disabled={!isButtonEnabled()}
                    sx={{ mt: 1, width: '70%' }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Container>
        </div>
    );      
};

export default ForgotPassword;