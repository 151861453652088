import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CustomPopUp from './CustomPopUp';
import { STUCK_GAME_API, GET_ALL_JOB} from '../../../api/stuckGameHandler';
import { useAuth } from '../../../hooks/AuthContext';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../helper/spinner/LoadingSpinner';
import './StuckGameJob.css';

const StuckGameJob = () => {
  const navigate = useNavigate();
  const [getAllJob, setAllJob] = useState([]);

  const [selectedJobId, setSelectedJobId] = useState('');
  const [selectedValues, setSelectedValues] = useState('');

  const [selectedEnablePartner, setEnablePartner] = useState(true);
  const [partnerIdStatus, setPartnerIdStatus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfiramationBoxOpen, setIsConfiramationBoxOpen] = useState(false);
  const [deleteJobPayload, setDeleteJobPayload] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const [editBgColor, setEditBgColor] = useState('favorite edit bgColor');
  const [deleteBgColor, setDeleteBgColor] = useState('favorite styled bgColor');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { headers, reset, roles } = useAuth();
  let editPopup = false;

  useEffect (() => {
    setErrorMessage('');
    //Set Access
    if(roles.ROLE_NORMAL) {
      setDisabled(true);
      setEditBgColor('favorite edit');
      setDeleteBgColor('favorite styled')
    }
    setIsLoading(true);
    getAllJobsCall();
  },[headers]);

  const openModal = (row) => {
    let buttonName = '';
    let titalHead = '';
    if(editPopup){ //edit
      setEnablePartner(false);
      setPartnerIdStatus(true);
      buttonName = "Update";
      titalHead = "Update";
      const updatedData = row;
      updatedData.buttonName = buttonName;
      updatedData.titalHead = titalHead;
      setSelectedValues(updatedData);
    }else{ //create
      setEnablePartner(true);
      setPartnerIdStatus(false);
      setSelectedValues(cleanPayload());
    }
    setIsModalOpen(true);
  };

  const cleanPayload = () => {
    const payload = {
      buttonName: "save",
      titalHead: "New",
      days: '',
      jobName: '',
      jobStatus: '',
      partnerId: '',
      scheduledTime: '',
      startDate: '',
      name: '',
      hour: '',
      minute: '',
      date:''
    }
    return payload ;
  }

  const editJob = (row) => {
    editPopup = true;
    const decryptedTime = formatTime(row.scheduledTime);
    const [hours, minutes] = decryptedTime.split(':');
    const date = formatDate(row.startDate);
    row.date = date;
    row.hour = Number(hours);
    row.minute = Number(minutes);
    setSelectedJobId(row.jobId);
    setSelectedValues(row);
    openModal(row);
  }

  const closeModal = () => {
    setSelectedJobId('');
    setIsModalOpen(false);
    setSelectedValues('');
  };

  const handleStatus = (data) => {
    return data !== "disabled";
  }

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  const formatTime = (time) => {
    const hours = String(Math.floor(time / 60)).padStart(2, '0');
    const minutes = String(time % 60).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const createJob = (data) => {
    if(partnerIdStatus){      // edit job
      const endpoint = "updateJob";
      const payload = addPayload(data);
      payload.jobId= Number(selectedJobId);
      postApiCall(payload, endpoint);
      setSelectedValues('');
      setIsModalOpen(false);
    }
    else{ // create new job
      getAllJobsCall();
      const isPartnerIdPresent = getAllJob.some(item => item.partnerId === selectedValues.partnerId);
      if(isPartnerIdPresent){
        alert("Job is already exists for the partner!! Please Edit the job. ");
        setSelectedValues(cleanPayload())
      } else{
        const endpoint = "createJob"
        postApiCall(addPayload(data), endpoint)
        setIsModalOpen(false);
        setSelectedValues('');
      }
    }
  }

  const addPayload = (data) => {
    let date = '';
    date = data.date+" "+data.hour+":"+data.minute+":00";
    const hour = Number(data.hour);
    const minute = Number(data.minute);
    const scheduledTime = (hour*60)+minute;
    const payload = {
      days: data.days,
      jobName: data.jobName,
      jobStatus: data.jobStatus,
      partnerId: data.partnerId,
      scheduledTime: scheduledTime,
      startDate: date
    }
    return payload
  }

  const changeState = (row) => {
    let jobStatus = "disabled";
    const endpoint = "updateJob";
    const date = formatDate(row.startDate)+" "+formatTime(row.scheduledTime)+":00"
    if (row.jobStatus === "disabled"){
      jobStatus = "enabled";
    }
    const payload = {
      days: row.days,
      jobStatus: jobStatus,
      jobId: row.jobId,
      jobName: row.jobName,
      partnerId: row.partnerId,     
      scheduledTime: row.scheduledTime,
      startDate: date
    }
    postApiCall(payload, endpoint)  
  }

  const deleteJob = () => {
      const endpoint = "deleteJob"
      const payload = deleteJobPayload;
      postApiCall(payload, endpoint);
      setIsConfiramationBoxOpen(false);
  };

  const confirmationBox = (row) => {
    const payload = {
      jobId: row.jobId,
      jobName: row.jobName,
      partnerId: row.partnerId
    }
    setDeleteJobPayload(payload);
    setIsConfiramationBoxOpen(true);
  }

  const postApiCall = (payload, endpoint) => {
    axios.post(STUCK_GAME_API + endpoint, payload, headers)
    .then((response) => {
      if(response.data.success){
        getAllJobsCall();
        window.flash(response.data.message,"success");
      }else{
        window.flash(response.data.message,"error");
      }
      closeModal();
    })
    .catch((error) => {
      if(error.response && error.response.status === 500){
        reset();
        navigate('/');
        window.flash("Session expired","error");
      }else{
        console.log('Error fetching user list:', error);
      }
    });
  }

  const getAllJobsCall = () => {
    axios.get(GET_ALL_JOB, headers)
    .then((response) => {
      if (response.data.body !== null){
        setErrorMessage('');
        setAllJob(response.data.body);
      } else {
        setErrorMessage('No job present.');
        setAllJob([]);
      }
      setIsLoading(false);
    })
    .catch((error) => {
      setErrorMessage('Something goes wrong....');
      setIsLoading(false);
      if(error.response && error.response.status === 500){
        reset();
        navigate('/');
        window.flash("Session expired","error");
      }else{
        console.log('Error fetching user list:', error);
      }
    });
  }

  return (
    <div class="alert alert-light">
      <div class="alert alert-light" id='stuck-game-job-search-button'>
        <h5 className='stuckgamejob-titel-div'>Close Game Job </h5>
        <div>
        <button class="favorite newjob" value="createJob"
          onClick={openModal} disabled={disabled}>Create New Job</button>
          <CustomPopUp  
          props = {{
            selectedValues,
            closeModal,
            isModalOpen,
            selectedEnablePartner,
            partnerIdStatus, 
            createJob
          }}/>
        </div>
      </div>
      {(getAllJob.length !== 0)  && (
      <div class="alert alert-light">
        <div class="table">
          <h5>Open All Jobs :</h5>
            <table>
            <thead>
              <tr>
                <th>Job Id</th>
                <th>Job Name</th>
                <th>Start Time</th>
                <th>Partner Id</th>
                <th>Schedule From</th>
                <th>No of old days</th>
                <th>Status</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
             <tbody>
              {getAllJob.map((row, index) => (
                <tr key={index}>
                  <td>{row.jobId}</td>
                  <td>{row.jobName}</td>
                  <td>{formatDate(row.startDate)}</td>
                  <td>{row.partnerId === 0 ? "All" : row.partnerId}</td>
                  <td>{formatTime(row.scheduledTime)}</td>
                  <td>{row.days}</td> 
                  <td>
                    <label class="switch">
                      <input
                        type="checkbox"
                        checked={handleStatus(row.jobStatus)}
                        onClick={() => changeState(row)}
                        disabled={disabled}
                      />
                      <span className="slider round"></span>
                    </label>
                  </td>
                  <td>
                    <button
                      class={editBgColor}
                      type="button" 
                      value="editJob"
                      disabled={disabled}
                      onClick={() => editJob(row)}
                      id='editJob'
                    >
                      Edit
                    </button>
                  </td>
                  <td>
                  <button
                      className={deleteBgColor}
                      type="button"
                      onClick={() => confirmationBox(row)}
                      disabled={disabled}
                      >
                      Delete
                    </button>
                    </td>
                </tr>
              ))}
                  {isConfiramationBoxOpen && (
                    <div className="modal">
                      <div className="confermationbox-content">
                        <h3 className="configrationBoxh3"> Close Job</h3>
                        <span
                          className="close"
                          onClick={()=>setIsConfiramationBoxOpen(false)}>
                            &times;
                        </span>
                        <div className="alert alert-light">
                          <h6 className="configrationBoxh6">Are you sure.</h6>
                          <h6 className="configrationBoxh6">You want to close the Job.</h6>
                        </div>
                        <button
                          class="favorite styled bgColor"
                          type="button"
                          onClick={()=>setIsConfiramationBoxOpen(false)}>
                          Cancel
                        </button>
                        <button
                          class="favorite newjob"
                          type="button"
                          onClick={deleteJob} >
                          Confirm
                        </button>
                      </div>
                  </div>
                )}
            </tbody>
         </table> 
        </div> 
      </div>
      )}
      {isLoading && (<LoadingSpinner/>)}
      {errorMessage && <h5>{errorMessage}</h5>}
    </div>
  )
}

export default StuckGameJob