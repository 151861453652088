import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import './SessionsList.css';

const SessionsList = (props) => {
  const { section, sessions } = props;
  const [isCopied, setIsCopied] = useState(Array(sessions.length).fill(false));
  const [showData, setShowData] = useState(false);

  const copyToClipboard = (value) => {
    const sessionData = sessions[value];

    const textArea = document.createElement('textarea');
    textArea.value = JSON.stringify(sessionData, null, null);

    document.body.appendChild(textArea);

    textArea.select();
    document.execCommand('copy');

    document.body.removeChild(textArea);

    const newIsCopied = [...isCopied];
    newIsCopied[value] = true;
    setIsCopied(newIsCopied);
  };

  const HandleTableData = () => {
    setShowData(!showData);
  };

  return (
    <div>
      <br />
      <button type="button" className="header-button" onClick={HandleTableData}>
        {section}
        :
        <FontAwesomeIcon icon={showData ? faMinus : faPlus} />
      </button>
      <ul className={showData ? 'session-data' : 'dissable'}>
        {sessions.map((session, value) => (
          <li key={value} className="column-data">
            {JSON.stringify(session, null, 1)}
            <br />
            <button type="button" className="copyButton" onClick={() => copyToClipboard(value)}>
              {isCopied[value] ? 'Copied' : 'Copy'}
            </button>
            <br />
            <br />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SessionsList;
