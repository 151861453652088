import { HashRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import Search from './components/Search/Search';
import Session from './components/Session';
import StuckGameHandler from './components/stuckGameHandler/closeStuckGame/StuckGameHandler';
import StuckGameJob from './components/stuckGameHandler/stuckGameJob/StuckGamejob';
import { useAuth } from './hooks/AuthContext';
import SignIn from './components/User/SignIn';
import NoMatch from './NoMatch';
import SuperAdminPanel from './components/adminPanel/SuperAdminPanel';
import AuditLog from './components/auditLog/AuditLog';
import { Flash } from './components/Flash';
import Bus from './components/Flash/Bus';
import ResetPassword from './components/resetPassword/ResetPassword';
import HelpPage from './components/HelpPage';
import ForgotPassword from './components/forgotPassword/ForgetPassword';

function App() {
  window.flash = (message, type = 'success') =>
    Bus.emit('flash', ({ message, type }));
  const { auth } = useAuth();

  return (
    <>
      <Flash />
      <HashRouter>
        <Routes>
          { auth
            ? (
              <Route path="/" element={<Navbar />}>
                <Route path="/search" element={<Search />} />
                <Route path="/session" element={<Session />} />
                <Route path="/stuck" element={<StuckGameHandler />} />
                <Route path="/stuck-game-job" element={<StuckGameJob />} />
                <Route path="/admin" element={<SuperAdminPanel />} />
                <Route path="/log" element={<AuditLog />} />
                <Route path="/help" element={<HelpPage />} />
              </Route>
            )
            : <Route path="*" element={<NoMatch />} /> }
          <Route path="/" element={<SignIn />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
