import { Button, TextField, Typography, Link } from '@mui/material';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useFormik } from 'formik';
import jwt_decode from 'jwt-decode';
import { useAuth } from '../../hooks/AuthContext';
import { LOGIN_API } from '../../api/userAPI';
import { EMAIL_REGEX, PASSWORD_REGEX } from '../../constants/Regexs';
import './signin.css'
import axios from 'axios';

// validations
const validate = (values) => {
  const errors = {};
  // Email validation
  if (!values.email) {
    errors.email = 'Required';
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  // Password validation
  if (!values.password) {
    errors.password = 'Required';
  } else if (!PASSWORD_REGEX.test(values.password)){
    errors.password = 'Password must be 10+ characters and include at least one lowercase letter, uppercase letter, number and a special character(!@#$%^&*-_+=).';
  }
  return errors;
};

function SignIn() {
  const [, setCookies] = useCookies(['token']);
  const {
    setAuth, setRoles, roles, reset,
  } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  // This method is use to set the role on sign in auth Context
  const assignRole = (AssignRole) => {
    const newRoles = roles;
    newRoles[AssignRole] = true;
    setRoles(newRoles);
  };
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate,
    validateOnChange: true,
    validateOnBlur: true, 
    onSubmit: async (values) => {
      const payload = {
        username: values.email,
        password: values.password,
      }
      setError("");
      axios.post(LOGIN_API, payload)
      .then((response) => {
        if (response.data.body === null){
          setError(response.data.message);
        }
        if (!response.data.body.requiredResetPassword) {
          if (response.data.body == null) {
            setError(response.data.message);
          }
          if (response.data.body.token) {
            setCookies('token', response.data.body.token, { path: '/' });
            setAuth("success");
            // Parse token to get role
            const user = jwt_decode(response.data.body.token);
            assignRole(user.role);
            navigate('/search');
            window.flash(response.data.message, 'success');
          }
        } else {
          setCookies('token', response.data.body.token);
          setAuth("success");
          navigate('/reset-password');
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500){
          reset();
          navigate('/');
          window.flash("Session expired","error");
        } else {
          console.error('Error making API call:', error);
        }
      });
    },
  });

  const handleForgotPassword = () => {
    navigate('/forgot-password');
};

  return (
    <Container component="main">
      <Box
        sx={{
          margin: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={formik.handleChange}
            value={formik.values.password}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Link 
          onMouseDown={(event) => {
            event.preventDefault();
            handleForgotPassword();
          }}
          variant="body2"
        > Forgot password?
          </Link>
          {error != "" &&
            <h6 id="errorMessage">{error}</h6>
          }
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default SignIn;