import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import PlayerIdSearchList from '../Search/PlayerIdSearchList';
import { AUDIT_LOG } from '../../api/auditLog';
import { useAuth } from '../../hooks/AuthContext';
import LoadingSpinner from '../helper/spinner/LoadingSpinner';

import { START_DATE_FORMAT, END_DATE_FORMAT, PAGE_SIZE } from '../../constants/DateFormat';
import Date from '../Search/Date';
import './AuditLog.css';

const AuditLog = () => {
  const { headers, reset } = useAuth();
  const navigate = useNavigate();
  const now = dayjs();
  const [auditLogs, setAuditLogData] = useState([]);

  const startDateFormat = now.format(START_DATE_FORMAT);
  const endDateFormat = now.format(END_DATE_FORMAT);

  const [startDate, setStartDate] = React.useState(dayjs(startDateFormat).subtract(7, 'day'));
  const [endDate, setEndDate] = React.useState(dayjs(endDateFormat));
  const [disabled, setDisabled] = useState(true);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [pageSize] = useState(PAGE_SIZE);
  const [showPagination, setShowPagination] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const getAuditLog = async () => {
      const formattedStartDate = startDate.format(START_DATE_FORMAT);
      const formattedEndDate = endDate.format(END_DATE_FORMAT);
      const queryParameters = `?pageNumber=${pageNumber}&pageSize=${pageSize}&fromDate=${formattedStartDate}&toDate=${formattedEndDate}`;

      await fetch(AUDIT_LOG + queryParameters, headers)
        .then((response) => response.json())
        .then((data) => {
          if (data.status && data.status === 500) {
            setErrorMessage('Session Expired');
            reset();
            window.flash('Session Expired', 'error');
            navigate('/');
          }
          if (data.body == null) {
            setError(true);
            setAuditLogData([]);
            setErrorMessage(data.message);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setAuditLogData(data.body);
            setTotalPages(data.totalPages);
            (totalPages === 1) ? setShowPagination(false) : setShowPagination(true);
          }
        })
        .catch((err) => console.log(err));
    };
    getAuditLog();
  }, [pageNumber]);
  
  const handleChange = (event, value) => {
    setPageNumber(value);
  };
  
  useEffect(() => {
    setDisabled(false);
  }, [startDate, endDate]);

  const search = async () => {
    setIsLoading(true);
    setDisabled(true);
    const api = AUDIT_LOG;
    const formattedStartDate = startDate.format(START_DATE_FORMAT);
    const formattedEndDate = endDate.format(END_DATE_FORMAT);
    const query = `?fromDate=${formattedStartDate}&toDate=${formattedEndDate}&
    pageNumber=${pageNumber}&pageSize=${pageSize}`;

    //API call according to parameter selected
    await fetch(api + query, headers)
      .then((response) => response.json())
      .then((data) => {
        if (data.status && data.status === 500) {
          setErrorMessage('Session Expired');
          reset();
          window.flash('Session Expired', 'error');
          navigate('/');
        }
        if (data.body != null) {
          setDisabled(false);
          setErrorMessage('');
          const result = Array.isArray(data.body) ? data.body : [data.body];
          setIsLoading(false);
          setAuditLogData(result);
          setTotalPages(data.totalPages);
          setError(false);
          (data.totalPages === 1) ? setShowPagination(false) : setShowPagination(true);
        } else {
          setDisabled(false);
          setError(true);
          setAuditLogData([]);
          setIsLoading(false);
          setErrorMessage(data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const renderAuditLogs = (
    <div className="alert alert-light">
      <div className="alert alert-light" id='audit-searchButton'>
        <Date
          props={{
            setStartDate,
            setEndDate,
            startDate,
            endDate,
          }}
        />
        <Button
          variant="contained"
          id="reset-button"
          disabled={disabled}
          onClick={search}
        >
          SEARCH
        </Button>
      </div>
      <PlayerIdSearchList
        players={auditLogs}
      />
      {!error && showPagination &&
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          color="primary"
          onChange={handleChange}
        />}
    </div>
  );

  return (
    <>
      {isLoading ? <LoadingSpinner /> : renderAuditLogs}
      { errorMessage && (
        <p className="error">
          {errorMessage}
        </p>
      )}
    </>
  );
};

export default AuditLog;
