import React, { useEffect, useState } from 'react';
import {
  INTERNAL_PLAYER, EXTERNAL_PLAYER,
  GAME_SESSION_ID, WALLET_SESSION_ID,
  WALLET_WAGER_ROUND_ID, GAME_WAGER_ROUND_ID,
} from '../../api/searchAPI';
import PlayersList from './PlayersList';
import SearchInput from './SearchInput';
import SessionTabPanel from '../session/SessionTabPanel';
import NoDataModal from '../session/NoDataModal';
import Rounds from '../session/Rounds';
import { CASINO_ARCHIVED_SESSION } from '../../api/casinoSession';
import { useAuth } from '../../hooks/AuthContext';
import LoadingSpinner from '../helper/spinner/LoadingSpinner';
import { useNavigate } from "react-router-dom";
import SingleSessionTabPanel from '../session/SingleSessionTabPanel';

function SearchBar(props) {
  const PERCENTAGE_ASCII_VALUE = "%25";
  const INTERNAL_SERVER_ERROR = 500;
  const { option } = props;
  const navigate = useNavigate();
  const { headers } = useAuth();
  const [query, setQuery] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isDataPresent, setIsDataPresent] = useState(false);

  // Internal players
  const [internalPlayers, setInternalPlayers] = useState([]);
  const [externalPlayers, setExternalPlayers] = useState([]);

  // By Session id
  const [gameSessionIds, setGameSessionIds] = useState([]);
  const [walletSessionIds, setWalletSessionIds] = useState([]);

  const [walletWagerRoundIds, setWalletWagerRoundIds] = useState([]);
  const [gameWagerRoundIds, setGameWagerRoundIds] = useState([]);

  // State use to show session tabs and hide player search tab
  const [showSession, setShowSession] = useState(false);
  const [selectedPlayerId, setSelectedPlayerId] = useState('');

  const [walletSessionId, setWalletSessionId] = useState('');
  const [rounds,] = useState([]);
  // Use these state when we have session but no rounds present
  const [displayNoDataModal, setDisplayNoDataModal] = useState(false);
  const [NoDataMessage, setNoDataMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const { reset } = useAuth();
  const [isSingleWalletSessionId, setIsSingleWalletSessionId] = useState(false);
  const [archivedSession, setArchivedSession] = useState([]);

  const resetAll = () => {
    setInternalPlayers([]);
    setExternalPlayers([]);
    setWalletSessionIds([]);
    setGameSessionIds([]);
    setWalletWagerRoundIds([]);
    setGameWagerRoundIds([]);
    setErrorMessage('');
    setShowSession(false);
    setArchivedSession([]);
    setIsDataPresent(false);
  };

  const handleInputChange = (inputValue) => {
    setQuery(inputValue);
  };

  const showSessionTab = async (clickedPlayerId) => {
    setShowSession(true);
    setSelectedPlayerId(clickedPlayerId);
    setErrorMessage('');
  };

  useEffect(() => {
    resetAll();
    setIsDataPresent(false);

    const handelChange = () => {
      setIsLoading(true);
      if (option === 'byPlayerId') {
        apiCall(INTERNAL_PLAYER, setInternalPlayers);
        apiCall(EXTERNAL_PLAYER, setExternalPlayers);
      }
      if (option === 'bySessionId') {
        apiCall(GAME_SESSION_ID, setGameSessionIds);
        apiCall(WALLET_SESSION_ID, setWalletSessionIds);
      }
      if (option === 'byRoundId') {
        apiCall(WALLET_WAGER_ROUND_ID, setWalletWagerRoundIds);
        apiCall(GAME_WAGER_ROUND_ID, setGameWagerRoundIds);
      }
      if (option === 'byAll') {
        apiCall(INTERNAL_PLAYER, setInternalPlayers);
        apiCall(EXTERNAL_PLAYER, setExternalPlayers);

        apiCall(GAME_SESSION_ID, setGameSessionIds);
        apiCall(WALLET_SESSION_ID, setWalletSessionIds);

        apiCall(WALLET_WAGER_ROUND_ID, setWalletWagerRoundIds);
        apiCall(GAME_WAGER_ROUND_ID, setGameWagerRoundIds);
      }
      // enable sessions tab if data is present
    };

    const concatPercentage = (query) => {
      if (query.indexOf('%') > -1) {
        let input = query.split("%");
        let firtString = input[0];
        let secondString = input[1];
        firtString+= PERCENTAGE_ASCII_VALUE; 
        let updatedQuery = firtString.concat(secondString);
        return updatedQuery;
      }
      return query;
    }

  const apiCall = (API,setterFunc ) => {

    let queryParameter = concatPercentage(query);

    if (queryParameter !== PERCENTAGE_ASCII_VALUE) {
      fetch(API + queryParameter, headers)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === INTERNAL_SERVER_ERROR) {
          setErrorMessage('Session Expired');
          reset();
          window.flash('Session Expired', 'error');
          navigate('/');
        }
        if (data.body !== null) {
          setIsDataPresent(true);
          setErrorMessage('');
          setterFunc(data);
        } else {
          setErrorMessage(data.message);
        }
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
    } else {
      setIsLoading(false);
      setErrorMessage("Invalid search value.")
    }
  };

    const timer = setTimeout(() => {
      if (query.length) handelChange();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };

  }, [query, option, headers]);

  // This modal popup gets called when there is active session but not wager rounds
  const showNoDataModal = () => {
    setNoDataMessage(`There are no wager rounds present for given wallet session ${walletSessionId}`);
    setDisplayNoDataModal(true);
  };

  // Hide No data modal
  const hideNoDataModal = () => {
    setDisplayNoDataModal(false);
  };

  const getWalletSession = async (selectedWalletSessionId, arenaServicePlayerId) => {
    setWalletSessionId(selectedWalletSessionId);
    await fetch(`${CASINO_ARCHIVED_SESSION}?sessionId=${selectedWalletSessionId}&playerId=${arenaServicePlayerId}`, headers)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === INTERNAL_SERVER_ERROR) {
          setErrorMessage('Session Expired');
          reset();
          window.flash('Session Expired', 'error');
          navigate('/');
        }
        if ((data.body == null) || (data.body.length === 0)) {
          showNoDataModal();
        } else {
          setIsSingleWalletSessionId(true);
          setArchivedSession([data.body]);
        }
      })
      .catch((err) => console.log(err));
  };

  const searchResult = (
    <>
      <PlayersList
        players={internalPlayers}
        section="Internal Players"
        showSessionTab={showSessionTab}
      />
      <PlayersList
        players={externalPlayers}
        section="External Players"
        showSessionTab={showSessionTab}
      />
      <PlayersList
        players={gameSessionIds}
        section="Game Session Ids"
        showSessionTab={showSessionTab}
        getWalletSession={getWalletSession}
      />
      <PlayersList
        players={walletSessionIds}
        section="Wallet Session Ids"
        show={showSessionTab}
        getWalletSession={getWalletSession}
      />

      <PlayersList
        players={gameWagerRoundIds}
        section="Game Wager Round Ids"
        showSessionTab={showSessionTab}
        getWalletSession={getWalletSession}
      />
      <PlayersList
        players={walletWagerRoundIds}
        section="Wallet Wager Round Ids"
        showSessionTab={showSessionTab}
        getWalletSession={getWalletSession}
      />
      {showSession && <SessionTabPanel selectedPlayerId={selectedPlayerId} />}

      {isSingleWalletSessionId && (archivedSession.length > 0)
      && <SingleSessionTabPanel archivedSession={archivedSession} />}

      <NoDataModal
        showModal={displayNoDataModal}
        hideModal={hideNoDataModal}
        message={NoDataMessage}
      />
      <Rounds
        walletSessionId={walletSessionId}
        rounds={rounds}
      />

      {(!isDataPresent && errorMessage) &&  (
        <h5>
          {errorMessage}
        </h5>
      )}
    </>
  );

  return (
    <>
      <SearchInput handleInputChange={handleInputChange} />
      {isLoading ? <LoadingSpinner /> : searchResult}
    </>
  );
}
export default SearchBar;