import React, { useState, useEffect, useRef } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './SearchInput.css';

const SearchInput = (props) => {

  const searchRef = useRef();
  const[query, setQuery] = useState('');

  useEffect(()=>{
    props.handleInputChange(query)
  }, [query, props]);

  const reset = () => {
    setQuery('')
    searchRef.current.reset();
  }

  const handlePaste = event => {
    setQuery(event.clipboardData.getData('text'));
  };

  const buttonStyle = {
    width: '40px',
  }

  const boxComponentStyle = {
    width: '40%',
    paddingRight: '40px'
  }

  return (
    <>
    <div>
      <Box
        component="form"
        sx = {
          {
          '& > :not(style)': { m: 1, width: '50ch' },
          'position': 'relative',
          'display': 'inline'
          }
        }
        noValidate
        autoComplete="off"
        ref={searchRef}
        style={boxComponentStyle}
      >
      <TextField 
        id="outlined-basic" 
        label="Search Here" 
        variant="outlined" 
        onChange={(e)=> setQuery(e.target.value)}
        onPaste={handlePaste}
      />
      <Button 
        variant="contained" 
        onClick={reset} 
        style={buttonStyle} 
        id="reset-button">
          Reset
      </Button>
      </Box>
    </div>
    </>
  )
}

export default SearchInput;