import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { ROLES } from '../../api/adminTab';
import { useAuth } from '../../hooks/AuthContext';
import { PASSWORD_REGEX, EMAIL_REGEX } from '../../constants/Regexs';
import './Popup.css';

const Popup = ({ isModalOpen, 
  setIsModalOpen,
  setSelectedValues,
  selectedValues,
  handleSubmit }) => {
  const [allRoles, setAllRoles] = useState([]);

  const [userEmailName, setUserEmailName] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const { headers, reset } = useAuth();

  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const navigate = useNavigate();

  useEffect (() => {
    axios.get(ROLES, headers)
    .then((response)=> {
      setAllRoles(response.data.body);
    })
    .catch((error) => {
      if(error.response && error.response.status === 500){
        reset();
        navigate('/');
        window.flash("Session expired","error");
      } else{
        console.log('Error fetching user list:', error);
      }
    })
  },[])

  const isButtonEnabled = () => {
    if (selectedValues.Header === "create"){
      return (
        name !== '' &&
        password !== '' &&
        userEmailName !== '' &&
        role !== '' && 
        !passwordError && !emailError
      );
    } else if (selectedValues.Header === "resetPassword"){
      return (
        password !== '' &&
        !passwordError
      );
    } else if (selectedValues.Header === "edit"){
          return (
            selectedValues.name !== '' && selectedValues.name.trim() !== ''
          );
    } else {
      return true;
    }
  };

  const handleEmailChange = (event) => {
    selectedValues.username = event.target.value;
    setUserEmailName(event.target.value);
    if (!EMAIL_REGEX.test(event.target.value)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  }

  const handleNameChange = (event) => {
    selectedValues.name = event.target.value;
    setName(event.target.value);
  }

  const handlePasswordChange = (event) => {
    selectedValues.password = event.target.value;
    setPassword(event.target.value);
    if (!PASSWORD_REGEX.test(event.target.value)) {
      setPasswordError('Password must be 10+ characters and include at least one lowercase letter, uppercase letter, number and a special character(!@#$%^&*-_+=).');
    } else {
      setPasswordError('');
    }
  }

  const handleRoleChange = (event) => {
    selectedValues.roleId = event;
    setRole(event);
  }

  const closePopup = () => {
    setIsModalOpen(false);
    const payload = {
      username: '',
      password:'',
      name: '',
      roleId: '',
      Header: ''};
    setSelectedValues(payload);
    setName('');
    setPassword('');
    setUserEmailName('');
    setPasswordError('');
    setRole('');
    setEmailError('');
  }

  const handlePopUpSubmit = () => {
    let payload = {};
    let apiType = '';
    if(selectedValues.Header === "create"){
        payload = {
        username: userEmailName,
        password: password,
        roleId: role,
        name: name
      }
      apiType = "create";
    }
    else if(selectedValues.Header === "edit"){
        payload = {
        username: selectedValues.username,
        roleId: selectedValues.roleId,
        name: selectedValues.name
      }
      apiType = "edit";
    }
    else{
        payload = {
        username: selectedValues.username,
        password: password
      }
      apiType = "resetPassword";
    }
    handleSubmit(payload, apiType);
  }

  return (
  <div>  
    <div className="App"> 
    {isModalOpen && (
      <div className="modal" >
        <div className="modalContent" id='popupbox'>

        <Container  >
          <span className="close" onClick={closePopup}>&times;</span>
          <h2>
            {selectedValues.Header === "create"
              ? "Create New User"
              : selectedValues.Header === "edit"
              ? "Update the User" : "Reset Password"
            }
          </h2>
          <div className="alert alert-light">
            <div>
              {selectedValues.Header === "create"  && (
                <TextField
                  margin='normal'
                  variant="outlined" 
                  required fullWidth 
                  label='Email Address' type='email'
                  autoComplete='selectedValues.username' 
                  onChange={handleEmailChange}
                />
              )}
              {(selectedValues.Header === "resetPassword" || selectedValues.Header === "edit") && (
                <h6>{selectedValues.username}</h6>
              )}
              {emailError && <p className="error-message">{emailError}</p>}
              <div>
              {(selectedValues.Header === "create" || selectedValues.Header === "resetPassword") && (
                <TextField
                  margin='normal' 
                  required fullWidth 
                  name='Password'
                  label='Password'
                  type='password'
                  autoComplete='selectedValues.password' 
                  onChange={handlePasswordChange}
                /> 
              )}  
              {passwordError && <p className="error-message">{passwordError}</p>}

              </div>
              <div>
                {(selectedValues.Header === "create" || selectedValues.Header === "edit") && (
                  <TextField
                    margin='normal' 
                    required fullWidth 
                    label='Name' 
                    type='name'
                    value={selectedValues.name}
                    onChange={handleNameChange}
                  />
                )}
              </div>
              <div>
              {(selectedValues.Header === "create" || selectedValues.Header === "edit") && (
                <TextField
                  margin='normal' 
                  select
                  label="Select Role"
                  variant="outlined"
                  value={selectedValues.roleId}
                  onChange={(e) => handleRoleChange(e.target.value)}
                  fullWidth
                >
                {allRoles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </TextField>
                )}
              </div>
            </div>
           </div>
          </Container>
          <div>
            <button className="favorite styled bgColor" type="submit" 
              onClick={closePopup}>
              Cancel
            </button>
            <button className="favorite newjob"                     
              disabled={!isButtonEnabled()} 
              onClick={handlePopUpSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div> 
    )}
    </div>
  </div>
  )
}

export default Popup;