import React from 'react';
import { TextField, MenuItem } from '@mui/material';

const SecurityQuestionField = ({ label, value, onChange, options }) => {
  return (
    <TextField
      margin="normal"
      select
      required
      fullWidth
      label={label}
      size="small"
      sx={{ mb: 0.5, width: '70%' }}
      value={value}
      onChange={onChange}
    >
        <MenuItem>Select</MenuItem>
        {options != '' && options.map((question) => (
        <MenuItem key={question.id} value={question.id}>
            {question.securityQuestion}
        </MenuItem>
        ))}
    </TextField>
  );
};

export default SecurityQuestionField;
