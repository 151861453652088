import React, {useState} from "react";
import LoadingSpinner from './../helper/spinner/LoadingSpinner';
import NoDataModal from "./NoDataModal";
import Rounds from "./Rounds";
import { useAuth } from '../../hooks/AuthContext';
import {CASIO_OPEN_ROUNDS} from '../../api/casinoSession';
import { useNavigate } from "react-router-dom";
import PlayerIdSearchList from '../Search/PlayerIdSearchList';


const SingleArchived = (props) => {
  const {archivedSession} = props;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [displayNoDataModal, setDisplayNoDataModal] = useState(false);
  const [NoDataMessage, setNoDataMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  // const[archivedSessions, setArchivedSessions] = useState([]);
  const[walletSessionId, setWalletSessionId] = useState('');
  const [rounds, setRounds] = useState([]);
  const { headers, reset} = useAuth();

   //This modal popup gets called when there is active session but not wager rounds
   const showNoDataModal = () => {
    setNoDataMessage("There are no wager rounds present for given wallet session " + walletSessionId);
    setDisplayNoDataModal(true);
  }

   //hide No data modal
   const hideNoDataModal = () => {
    setDisplayNoDataModal(false);
  };

  const getWagerRounds = async(walletSessionId) => {
    setWalletSessionId(walletSessionId);
    let query = `sessionId=${walletSessionId}`;
      setIsLoading(true)
      await fetch(CASIO_OPEN_ROUNDS + query, headers)
        .then(response => response.json())
        .then(data => {

          if(data.status && data.status === 500) {
            setErrorMessage('Session Expired');
            reset();
            window.flash('Session Expired', 'error');
            navigate('/')
          }
          if(data.body.length === 0) {
            showNoDataModal();
          }else {
            setRounds(data.body);
          }
          setIsLoading(false)
        })
        .catch(err => console.log(err));
  }
  const renderArchivedSessions = ( 
    <PlayerIdSearchList 
    players={archivedSession} 
    getWalletSessionId={getWagerRounds}/>
  )
  return(
    <>
      {isLoading ? <LoadingSpinner /> : renderArchivedSessions}
      <NoDataModal showModal={displayNoDataModal} hideModal={hideNoDataModal} 
      message={NoDataMessage} />
      { errorMessage && (
        <p className="error"> 
          {"There are no archive sessions for selected dates"} 
        </p>)
      }
       <Rounds walletSessionId = {walletSessionId} rounds ={rounds} />
    </>
  );
}

export default SingleArchived;