import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import { useNavigate } from 'react-router-dom';

import PlayerIdSearchList from '../Search/PlayerIdSearchList';
import LoadingSpinner from '../helper/spinner/LoadingSpinner';
import NoDataModal from './NoDataModal';
import Rounds from './Rounds';
import { useAuth } from '../../hooks/AuthContext';
import { START_DATE_FORMAT, END_DATE_FORMAT, PAGE_SIZE } from '../../constants/DateFormat';
import { CASINO_ARCHIVED_SESSIONS, CASINO_ARCHIVED_SESSION,
  CASIO_OPEN_ROUNDS } from '../../api/casinoSession';
import Date from '../Search/Date';
import './Archived.css';

const Archived = (props) => {
  const navigate = useNavigate();
  const { selectedPlayerId } = props;
  const [archivedSessions, setArchivedSessions] = useState([]);
  const now = dayjs();
  const startDateFormat = now.format(START_DATE_FORMAT);
  const endDateFormat = now.format(END_DATE_FORMAT);

  const [startDate, setStartDate] = React.useState(dayjs(startDateFormat).subtract(7, 'day'));
  const [endDate, setEndDate] = React.useState(dayjs(endDateFormat));
  const [disabled, setDisabled] = useState(true);
  const [sessionId, setSessionId] = useState('');

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [pageSize] = useState(PAGE_SIZE);
  const [showPagination, setShowPagination] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  // These states used for wallet session state
  const [walletSessionId, setWalletSessionId] = useState('');
  const [rounds, setRounds] = useState([]);

  // Use these state when we have session but no rounds present
  const [displayNoDataModal, setDisplayNoDataModal] = useState(false);
  const [NoDataMessage, setNoDataMessage] = useState('');
  const [bySession, setBySession] = useState(true);
  const [byDate, setByDate] = useState(false);

  const { headers, reset } = useAuth();

  // This function gets called on click of filter
  const search = async () => {
    setDisabled(true);
    setIsLoading(true);
    let query = `?playerId=${selectedPlayerId}`;
    let api = CASINO_ARCHIVED_SESSION;

    // Check if there is any data present in session id field
    if (sessionId) {
      query += `&sessionId=${sessionId}`;
      setShowPagination(false);
    } else {
      const formattedStartDate = startDate.format(START_DATE_FORMAT);
      const formattedEndDate = endDate.format(END_DATE_FORMAT);
      query += `&fromDate=${formattedStartDate}&toDate=${formattedEndDate}&
      pageNumber=${pageNumber}&pageSize=${pageSize}`;
      api = CASINO_ARCHIVED_SESSIONS;
    }
    // API call according to parameter selected
    await fetch(api + query, headers)
      .then((response) => response.json())
      .then((data) => {
        if (data.status && data.status === 500) {
          setErrorMessage('Session Expired');
          reset();
          window.flash('Session Expired', 'error');
          navigate('/');
        }
        if (data.body != null) {
          setDisabled(false);
          setErrorMessage('');
          const result = Array.isArray(data.body) ? data.body : [data.body];
          setIsLoading(false);
          setArchivedSessions(result);
          setTotalPages(data.totalPages);
          setError(false);
          (data.totalPages === 1) ? setShowPagination(false) : setShowPagination(true);
        } else {
          setDisabled(false);
          setError(true);
          setArchivedSessions([]);
          setIsLoading(false);
          setErrorMessage(data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const getArchivedSessions = async () => {
      const playerId = selectedPlayerId;
      const formattedStartDate = startDate.format(START_DATE_FORMAT);
      const formattedEndDate = endDate.format(END_DATE_FORMAT);
      const queryParameters = `?pageNumber=${pageNumber}&playerId=${playerId}&pageSize=${pageSize}&fromDate=${formattedStartDate}&toDate=${formattedEndDate}`;

      await fetch(CASINO_ARCHIVED_SESSIONS + queryParameters, headers)
        .then((response) => response.json())
        .then((data) => {
          if (data.status && data.status === 500) {
            setErrorMessage('Session Expired');
            reset();
            window.flash('Session Expired', 'error');
            navigate('/');
          }
          if (data.body == null) {
            setError(true);
            setArchivedSessions([]);
            setErrorMessage(data.message);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setArchivedSessions(data.body);
            setTotalPages(data.totalPages);
            (totalPages === 1) ? setShowPagination(false) : setShowPagination(true);
          }
        })
        .catch((err) => console.log(err));
    };
    getArchivedSessions();
  }, [selectedPlayerId, pageNumber]);

  const handleChange = (event, value) => {
    setPageNumber(value);
  };

  useEffect(() => {
    setDisabled(false);
  }, [startDate, endDate]);

  // This modal popup gets called when there is active session but not wager rounds
  const showNoDataModal = () => {
    setNoDataMessage('There are no wager rounds present for given wallet session ' + walletSessionId);
    setDisplayNoDataModal(true);
  };

  // hide No data modal
  const hideNoDataModal = () => {
    setDisplayNoDataModal(false);
  };

  const getWagerRounds = async (walletSessionId) => {
    setWalletSessionId(walletSessionId);
    const query = `sessionId=${walletSessionId}`;
    await fetch(CASIO_OPEN_ROUNDS + query, headers)
      .then((response) => response.json())
      .then((data) => {
        if (data.status && data.status === 500) {
          setErrorMessage('Session Expired');
          reset();
          window.flash('Session Expired', 'error');
          navigate('/');
        }
        if (data.body.length === 0) {
          showNoDataModal();
        } else {
          setRounds(data.body);
        }
      })
      .catch((err) => console.log(err));
  }

  const handleRadioButtonChange = (event) => {
    setSessionId('');
    if (event.target.value === "bySession") {
      setBySession(true);
      setByDate(false)
    } else {
      setBySession(false);
      setByDate(true)
    }
  }
  const renderArchivedSessions = ( 
    <div>
      <div>
        <div className='alert alert-light' id='filterButton'>
          <h5 className='archived-titel-div'>Selected Player ID - {selectedPlayerId}</h5>
          <div className='horizontalRedioButton'> 
            <div className="radioBox">
              <input
                className="form-check-input"
                type="radio"
                value='bySession'
                onChange={handleRadioButtonChange}
                checked={bySession}
              />
              <label>By Date</label>
            </div>
            <div className="radioBox">
              <input
                className="form-check-input"
                type="radio"
                value='byDate'
                onChange={handleRadioButtonChange}
                checked={byDate}
              />
              <label>By SessionId</label>
            </div>
          </div>
          <div className='searchField'>
            {byDate && (
              <div className='firstDiv'>
                <TextField
                  id="outlined-basic"
                  label="Session ID"
                  variant="outlined" 
                  value={sessionId}
                  onChange={(event)=>{setSessionId(event.target.value)}}
                />
              </div>
            )}
            {bySession && (
              <div className='secondDiv'>
                <Date
                  props={{
                    setStartDate,
                    setEndDate,
                    startDate,
                    endDate,
                  }}
                />
              </div>
            )}
            <Button
              variant="contained"
              id="reset-button"
              disabled={disabled}
              onClick={search}
            >
              SEARCH
            </Button>
          </div>
        </div>
        <PlayerIdSearchList
          players={archivedSessions}
          getWalletSessionId={getWagerRounds}
        />
        {!error &&  showPagination &&
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          />
        }
      </div>
  </div> 
 );
  return (
    <>
      {isLoading ? <LoadingSpinner /> : renderArchivedSessions}
      <NoDataModal
        showModal={displayNoDataModal}
        hideModal={hideNoDataModal} 
        message={NoDataMessage}
      />
      { errorMessage && (
        <p className="error"> 
          {"There are no archive sessions for selected dates"} 
        </p>
        )}
      <Rounds walletSessionId={walletSessionId} rounds={rounds} />
    </>
  );
};

export default Archived;
