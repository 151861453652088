import React from 'react';
import SessionsList from '../SessionList';

const SessionDetails = (props) => {
  const { casinoSessions } = props;

  // Prepare Wager close data
  let closeWagers = [];
  if (casinoSessions && casinoSessions['Closed wagers']) {
    closeWagers = casinoSessions['Closed wagers'];
  }

  // Prepare Open wagers
  let openWagers = [];
  if (casinoSessions && casinoSessions['Open wagers']) {
    openWagers = casinoSessions['Open wagers'];
  }

  let archivedSessions = [];
  if (casinoSessions && casinoSessions['Archived session']) {
    archivedSessions = casinoSessions['Archived session'];
  }

  let auditSessions = [];
  if (casinoSessions && casinoSessions['Audit session']) {
    auditSessions = casinoSessions['Audit session'];
  }

  let externalUnconfirmedTransactions = [];
  if (casinoSessions && casinoSessions['External unconfirmed transactions']) {
    externalUnconfirmedTransactions = casinoSessions['External unconfirmed transactions'];
  }

  let arenaUnconfirmedTransactions = [];
  if (casinoSessions && casinoSessions['Arena unconfirmed transactions']) {
    arenaUnconfirmedTransactions = casinoSessions['Arena unconfirmed transactions'];
  }

  return (
    <>
      <SessionsList sessions={openWagers} section="Open Wagers" />
      <SessionsList sessions={archivedSessions} section="Archived Sessions" />
      <SessionsList sessions={closeWagers} section="Close Wagers" />
      <SessionsList sessions={auditSessions} section="Audit Sessions" />
      <SessionsList sessions={externalUnconfirmedTransactions} section="External unconfirmed transactions" />
      <SessionsList sessions={arenaUnconfirmedTransactions} section="Arena unconfirmed transactions" />
    </>
  );
};

export default SessionDetails;
