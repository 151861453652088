import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './Date.css'

const Date = ({ props }) => {
  const { setStartDate, setEndDate, startDate, endDate } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker', 'DatePicker']}>
        <DatePicker
          className='startend-date'
          label="Start Date"
          value={startDate}
          onChange={(date) => setStartDate(date)}
        />
        <DatePicker
          className='startend-date'
          label="End Date"
          value={endDate}
          onChange={(date) => setEndDate(date)}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default Date;
