import React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const PasswordField = ({ label, value, onChange, showPassword, toggleShowPassword, error, helperText }) => {
  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      label={label}
      variant="outlined"
      fullWidth
      required
      value={value}
      onChange={onChange}
      margin="normal"
      error={!!error}
      helperText={helperText}
      size="small"
      sx={{ mb: 0.5, width: '70%' }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={toggleShowPassword}
              edge="end"
              tabIndex="-1"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
