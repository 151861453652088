import React, { useState , useEffect}from 'react';
import axios from 'axios';
import { Outlet, Link, NavLink, useNavigate }
  from 'react-router-dom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../hooks/AuthContext';
import { LOGOUT_API, HEALTH_CHECK_API } from '../api/userAPI';
import { GiHamburgerMenu } from "react-icons/gi";
import './Navbar.css';
import Popover from '@mui/material/Popover';
import { RETRY_TIME } from '../constants/DateFormat.js';

function Navbar() {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const { user, token, roles, reset } = useAuth();
  const [changeButton, setChangeButton] = useState(true);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [healthCheckdata, setHealthCheckData] = useState("");

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const logout = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
      },
    };
    const response = await fetch(LOGOUT_API, requestOptions);
    if (response.status === 200 || response.status === 403 || response.status === 405 || response.status === 500) {
      reset();
      navigate('/');
    } else {
      console.log('There is issue in logout');
    }
  };

  const isThirdActive = () => { 
    return (location.hash === '#/stuck') || (location.hash === '#/stuck-game-job')
  };

  const handleNavLinkClick = () => {
    setShowMediaIcons(false);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getServiceColor = (value) => {
    return value ? 'green' : 'red';
  };

  const getStatusSymbol = (value) => {
    return value ? '\u2714' : '\u2716';
  };

  const setBackendDown = () => {
    const data = {
      data:
      {"COAT service is down": false}
    };
    setHealthCheckData(data);
  };

  const setHealthButtonColor = (value) => {
    let counter = 0;
    if (!value){
      counter = sessionStorage.getItem("counter");
      counter ++;
      if (counter > 2){
        counter = 0;
        setChangeButton(false);
      }
    } else {
      counter = 0;
      setChangeButton(true);
    }
    sessionStorage.setItem("counter", counter);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(HEALTH_CHECK_API); // Timeout after 10 seconds
        const anyFalseValue = Object.values(response.data).some(value => value === false);
        setHealthCheckData(response);
        setHealthButtonColor(anyFalseValue ? false : true);
      } catch (error) {
        console.log('Error fetching Health check:', error);
        setChangeButton(false);
        setBackendDown();
      }
    };
  
    fetchData();
      const interval = setInterval(fetchData, RETRY_TIME);
      return () => clearInterval(interval);
  }, []);

  return (
    <>
      <nav className="navigation navbar-expand-lg navbar-light" >
        <Link
          to="/search"
          className="app-name"
        >
          COAT
        </Link>
           <div className="hamburger-menu">
            <a onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <GiHamburgerMenu />
            </a>
          </div>
        <div className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }
          id="navbarSupportedContent">
          <ul className="nav nav-pills nav-fill">
            <li className="nav-item active">
              <NavLink
                to="/search"
                className="nav-link"
                onClick={handleNavLinkClick}
              >
                Search
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/session"
                className="nav-link"
                onClick={handleNavLinkClick}
              >
                Casino Session
              </NavLink>
            </li>
            <li className="nav-item dropdown">
              <div className={`dropdown ${isThirdActive() ? 'active-button' : 'not-active'}`}>
                <button
                  className="btn  dropdown-toggle"
                  type="button"
                  id={`${isThirdActive() ? 'active-button' : 'not-active'}`}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Stuck Game
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li><NavLink to="/stuck" className="dropdown-item" onClick={handleNavLinkClick}>Close Stuck Game</NavLink></li>
                  <li><NavLink to="/stuck-game-job" className="dropdown-item" onClick={handleNavLinkClick}>Stuck Game Job</NavLink></li>
                </ul>
              </div>
            </li>
            {(roles.ROLE_SUPER_ADMIN)
            && <li className="nav-item">
              <NavLink
                to="/admin"
                className="nav-link"
                onClick={handleNavLinkClick}
              >
                Admin
              </NavLink>
              </li>}
            <li className="nav-item">
              <NavLink
                to="/log"
                className="nav-link"
                onClick={handleNavLinkClick}
              >
                Audit Logs
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/help"
                className="nav-link"
                onClick={handleNavLinkClick}
              >
                <FontAwesomeIcon icon={ faQuestionCircle } />
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="userTab">
            <button
              className='health-common'
              id={changeButton ? "img-button" : "img-button-error"}
              onClick={handlePopoverOpen}
            ></button>
             <div >
              <Popover
                id="simple-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{marginTop:'10px'}}
              >
              {healthCheckdata && (
                   <div id='popup-status'>
                   {Object.keys(healthCheckdata.data).map((key) => (
                     <Typography
                       key={key}
                       sx={{ p: 0.5, color: getServiceColor(healthCheckdata.data[key]), fontSize: '0.8em' }}
                     >
                       {getStatusSymbol(healthCheckdata.data[key])} {key}
                     </Typography>
                   ))}
                 </div>
              )}
            </Popover>
          </div>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <h6 className='userName'>{user && user.username}
                  <h6 className='userRole'>
                    {(roles.ROLE_SUPER_ADMIN) && '(Super Admin)'}
                    {(roles.ROLE_ADMIN) && '(Admin)'}
                    {(roles.ROLE_NORMAL) && '(User)'}
                  </h6>
                </h6>
                <Avatar alt="Admin" src="" sx={{ marginLeft: 1 }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={logout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </div>
      </nav>
      <div className="outlet">
        <Outlet />
      </div>
    </>
  );
}

export default Navbar;
