import React from 'react';
import { TextField } from '@mui/material';

const CustomTextField = ({ id, label, name, autoComplete, autoFocus, value, onChange, error, helperText }) => {
    return (
        <TextField
            margin="normal"
            required
            fullWidth
            id={id}
            label={label}
            name={name}
            error={!!error}
            helperText={helperText}
            autoComplete={autoComplete}
            size="small"
            sx={{ mb: 0.5, width: '70%' }}
            autoFocus={autoFocus}
            value={value}
            onChange={onChange}
        />
    );
};

export default CustomTextField;