import React from 'react';
import { Link } from 'react-router-dom';

function NoMatch() {
  return (
    <>
      <h3> No matching route found</h3>
      <Link to="/">Go to login page</Link>
    </>
  );
}

export default NoMatch;
