const URL = process.env.REACT_APP_COAT_SERVER || '';

function prepare(URI) {
  return (URL + URI);
}

export const CASINO_SESSION_API = prepare('api/casino/session?query=');
export const CASINO_ARCHIVED_SESSIONS = prepare('api/casino/archived/sessions');
export const CASINO_ARCHIVED_SESSION = prepare('api/casino/archived/session');
export const CASINO_OPEN_SESSION = prepare('api/casino/open/session');
export const CASIO_OPEN_ROUNDS = prepare('api/casino/session/wager/rounds?');
export const CASINO_CLOSE_WALLET_SESSION = prepare('api/casino/close/session/');
