import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import './HelpPage.css';
const HelpPage = () => {
	const data = [
        { Functionality: 'Admin Operations',      SuperAdmin: 'yes',  Admin: 'no', User: 'no' },
		{ Functionality: "Close Stuck Games",     SuperAdmin: 'yes',  Admin: 'yes', User: 'no' },
		{ Functionality: "Stuckgame Job --> Modification/Operation", SuperAdmin: 'yes', Admin: 'yes', User: 'no' },
		{ Functionality: "Close Open Session",    SuperAdmin: 'yes',  Admin: 'yes', User: 'NO' },
		{ Functionality: "Open Session",          SuperAdmin: 'yes',  Admin: 'yes', User: 'yes' },
        { Functionality: 'Search --> Player, Session Id, Round Id, All', SuperAdmin: 'yes',  Admin: 'yes', User: 'yes' },
        { Functionality: 'Casino Session Search', SuperAdmin: 'yes',  Admin: 'yes', User: 'yes' },
		{ Functionality: "Audit Logs",            SuperAdmin: 'yes',  Admin: 'yes', User: 'yes' },
		{ Functionality: "Archived Session",      SuperAdmin: 'yes',  Admin: 'yes', User: 'yes' },
		{ Functionality: "Stuckgame Job --> View", SuperAdmin: 'yes',  Admin: 'yes', User: 'yes' },
  ];

	return (
		<div className='helpPage'>
			<h3 style={{marginLeft:0}}>Help page</h3>
			<div className='subContent'>
			<h5>About COAT application</h5>
				<ul>
				<p>COAT is the application where the Player's Transaction related information is tracked. COAT has the details of Player basic details, active & inactive transaction details, games played, number of transaction taken place during the session, win-loss average details.</p>
				<p>COAT has the option to view the Replay for the spin/bet made during the session. It holds the control to Close the active session and stuck session occured due to the failures.</p>
						The below Tabs are the most common tabs which used to handle the Player data,
				<ul>
					<li>Search</li>
					<li>Casino Session</li>
					<li>Stuckgame</li>
					<li>Admin</li>
					<li>Audit Logs</li>
				</ul>
				</ul>
				<ul>

					<li><b>Search</b></li>
						Search Tab is used to Search a Player/Player detail with the help 4 option as below,
					<ul>
						<li>Using Player ID - Player ID is either the Player Name or Player Internal ID generated by RGS.</li>
						<li>Using Session ID - Session ID is the ID generated when a game session gets loaded.</li>
						<li>Using Round ID - Round ID the the ID which generated when a Player triggered a spin/bet during a session.</li>
						<li>Using All - Above any of the above option.</li>
					</ul>
					<br></br>

					<li><b>Sessions</b></li>
					<ul>
						<li>Archived session - Player closed sessions. </li>
						<li>Open session - Player opened session. </li>
					</ul>
					<br></br>

					<li><b>Casino Session</b></li>
					<h6>Casino Session Tab used to view the extended transaction details during a Session, with the help of Session ID.</h6>
					Cassino Sessions have subtables that hold specific types of data,
						<ul>
							<li>Open Wagers</li>
							<li>Archived Session</li>
							<li>Close Wagers</li>
							<li>Audit Session</li>
							<li>External unconfirmrd transactions</li>
							<li>Arena unconfirmrd transactions</li>
						</ul>
						<br></br>

					<li><b>Stuckgame</b></li>
						Stuckgame Tab used to Close the transactions stuck because of any error or normally unclosed and it has 2 tabs,
						<ul>
							<li>Close Stuckgame - This used close the transactions with the help of Player ID or Game.</li>
							<li>Stuckgame Job - This used schedule a Job which closes the stuck transaction.</li>
						</ul>
						<br></br>

					<li><b>Admin</b></li>
						Admin tab used to Create/Delete/Modify User detail and access.
					<li><b>Audit Logs</b></li>
						<h6>Audit Logs tab contains the Log details carried out in the COAT.</h6>
				</ul>
				<h5 style={{marginTop:5}}>User Access Privileges</h5>
				<div style={{marginLeft:5}}>
					<p>
						COAT has 3 types of Users who holds different privilages according to the role: Super Admin, Admin, and User.				
					</p>
					<h6>Super Admin:</h6>
					<ul>
						<li>Super Admin has unrestricted access to all functionalities within the application.</li>
						<li>They have the highest level of privilege and can modify operations across all tabs.</li>
					</ul>
					<h6>Admin:</h6>
					<ul>
						<li>Admin users also have access to all functionalities within the application.</li>
						<li>However, there are limitations compared to Super Admin:</li>
						<ul>
							<li>Admin users cannot modify operations within the Admin tab.</li>
						</ul>
						<li>Apart from the Admin tab restriction, they have full access to other functionalities.</li>
						<li>This role is indicated for all functionalities except the Admin tab.</li>
					</ul>
					<h6>Normal User:</h6>
					<ul>
						<li>Normal users have restricted access within the application.</li>
						<li>They are primarily limited to read-only access and cannot modify any operations.</li>
						<li>Normal users have view only access to certain functionalities.</li>
						<li>This includes functionalities such as "Open Session", "Search", "Casino Session Search", "Audit Logs", "Archived Session", and "Stuckgame Job (View)".</li>
					</ul>
					<br></br>
				</div>
			</div>
			<h5 style={{marginTop:5}}>User access table</h5>
			<table>
				<thead>
					<tr>
						<th>Functionality</th>
						<th>Super Admin</th>
						<th>Admin</th>
						<th>User</th>
					</tr>
				</thead>
				<tbody>
					{data.map(item => (
					<tr>
						<td>{item.Functionality}</td>
						<td>
						{item.SuperAdmin === 'yes' ? (
							<FontAwesomeIcon icon={faCheck} className="yes-icon" />
						) : (
							<FontAwesomeIcon icon={faTimes} className="no-icon" />
						)}
						</td>
									<td>
						{item.Admin === 'yes' ? (
							<FontAwesomeIcon icon={faCheck} className="yes-icon" />
						) : (
							<FontAwesomeIcon icon={faTimes} className="no-icon" />
						)}
						</td>
									<td>
						{item.User === 'yes' ? (
							<FontAwesomeIcon icon={faCheck} className="yes-icon" />
						) : (
							<FontAwesomeIcon icon={faTimes} className="no-icon" />
						)}
						</td>
					</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

export default HelpPage;
