import './CustomPopUp.css';

const Labels = () => {
    return (
        <div>
            <div>
                <labal className="popupLabal">Job Name </labal>
                <labal className="popupLabal">Schedule From</labal>
                <labal className="popupLabal">Partner </labal>
                <labal className="popupLabal">Status </labal>
                <labal className="popupLabal">No of old days</labal>
            </div>
        </div>
    )
}

export default Labels;