import { useEffect, useState } from "react";
import SearchInput from "./Search/SearchInput";
import {CASINO_SESSION_API} from './../api/casinoSession';
import SessionDetails from './session/SessionDetails';
import { useAuth } from '../hooks/AuthContext';
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "./helper/spinner/LoadingSpinner";

const Session = () => {
  const navigate = useNavigate();
  const { token, headers, reset } = useAuth();
  //Search input for casino session
  const[casinoSearch, setCasinoSearch] = useState([]);

  //Casino Session result
  const [casinoSessions, setCasinoSessions] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (searchValue)=>{
    setCasinoSearch(searchValue);
  }

  useEffect(()=> {
    const fetcher = async(API) => {
      setIsLoading(true);
      try {
        const request  =  await fetch(API + casinoSearch, headers);
        const response = await request.json();
        setIsLoading(false);
        if(response.status === 500) {
          setErrorMessage(response.message);
          reset();
          navigate('/');
        } 

        if(response.body == null) {
          setErrorMessage(response.message);
          setCasinoSessions('')
        } else {
          setCasinoSessions(response.body);
          setErrorMessage('');
        }   
        setIsLoading(false); 
      } catch(err) {
        console.log("There is an error " + err);
      }
    }
    const timer = setTimeout(() => {
      if(casinoSearch.length) fetcher(CASINO_SESSION_API);
      setErrorMessage('');
    }, 500);
  
    //Cleanup function gets called  when use effect is called again or on unmount
    return() => {
     clearTimeout(timer);
    };
  },[casinoSearch, token, headers])

  return (
    <>
      <div className="alert alert-light">
        <div className="alert alert-light" id='search-button'>
          <SearchInput handleInputChange={handleInputChange}/>
        </div>
        {casinoSessions && casinoSearch  &&(
          <SessionDetails casinoSessions={casinoSessions}/>
        )}
        {errorMessage && casinoSearch  && (
          <h5 className="responseMessage">{errorMessage}</h5>
        )}
        {isLoading && <LoadingSpinner /> }
      </div>
    </>
  );
}

export default Session;