import { Link } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import './PlayerIdSearchList.css';

function PlayerIdSearchList(props) {
  const { showCloseModal, clickable, players, getWalletSession, 
    showSessionTab, getWalletSessionId, section} = props;

  let column = [];
  // get table column
  if (players && players.length >= 1) {
    column = Object.keys(players[0]);
  }

  // get table heading data
  const ThData = () => {
    return column.map((data) => {
      if (data === 'loss' || data === 'gain' || data === 'result' ) {
        data = `${data}`
      }
      return <th className ="col-2" key={data}>{data}</th>
    });
  };

  const onChange = (playerId) => {
    showSessionTab(playerId);
  };

  const getWalletSessionCallback = (walletSessionId, arenaServicePlayerId) =>{
    getWalletSession(walletSessionId, arenaServicePlayerId);
  };

  const prepareLink = (columnName, data) => {
    switch (columnName) {
      case 'internalPlayerId':
        return (
          <td className ="col-1" key={data[columnName]}>
            <Link href="#" onClick = { 
              event => {onChange(data[columnName]);}
            }>
              {data[columnName]}
            </Link>
          </td>
        );
      case 'close':
        return (
          <td className ="col-1" >
            <Link
              style={{pointerEvents: clickable ? '' : 'none'}}
              href="#" onClick={() => showCloseModal(data['gameSessionId'])}>
              {data[columnName]}
            </Link>
          </td>
        );
      case 'walletSessionId':
        if (getWalletSessionId === undefined && getWalletSession === undefined) {
          return (
            <td className ="col-1">
              {data[columnName]}
            </td>
          );
        }
        if(getWalletSessionId) {
          return (
            <td className ="col-1" >
             <Link href="#" onClick={(event) => {getWalletSessionId(data[columnName])}}>
               {data[columnName]}
             </Link>
            </td>
          );
        }

        if(getWalletSession) {
          return (
            <td className ="col-1" >
             <Link href="#" onClick={(event) => {getWalletSession(data[columnName], data['arenaServicePlayerId'])}}>
               {data[columnName]}
             </Link>
            </td>
          );
        }
        
      case 'sessionId':
      case 'walletWagerRoundId':
        return (
          <td className ="col-1" >
            <Link href="#" onClick={(event) => {
              let walletSessionId = data['sessionId'];
              if (!data['sessionId']) {
                walletSessionId = data['walletSessionId'];
              }
              getWalletSessionCallback(
                walletSessionId,
                data['arenaServicePlayerId']
              )
            }}>
              { data[columnName] }
            </Link>
          </td>
        );
      case 'replayUrl':
        if (data['replayUrl'] !== null){
          return (
            <td className ="col-1" >
              <Link href="#" onClick={(event) => {window.open(data[columnName], '_blank').focus();}}>
                {"Click Here"}
              </Link>
            </td>
          );
        } else {
          return (
            <td className ="col-1" >
              <h6 id="noReplay">{"verify replay service"}</h6>
            </td>
          );
        }
      case 'loss':
      case 'gain':
      case 'result':
        return (
          <td className ="col-1">
            {`€ ${data[columnName]}`}
          </td>
        );
      default: return (
        <td className ="col-1">
          {data[columnName]}
        </td>
      );
    }
  }

  const tdData = () => {
    return players && players.map((data) => {
      return (
        <tr key={data.id}>
          {column && column.map((columnName) => {
            let cellContent = null;
            let cellStyle = {}; 
  
            if (columnName === 'status') {
              if (data[columnName]) {
                cellContent = 'Success';
                cellStyle = { color: 'green' };
              } else {
                cellContent = 'Failure';
                cellStyle = { color: 'red' };
              }
            } else {
              cellContent = prepareLink(columnName, data);
            }
            return (
              <td key={columnName} style={cellStyle}>
                {cellContent}
              </td>
            );
          })}
        </tr>
      );
    });  
  }

  return (
    <>
      <div className='tableDiv'>
        {section}
        <Table striped bordered hover>
          <thead> <tr className="tablerow"> {ThData()} </tr> </thead>
          <tbody> {tdData() } </tbody>
        </Table>
      </div>
    </>
  );
}

export default PlayerIdSearchList;
