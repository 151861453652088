import React from "react";
import { Modal } from 'react-bootstrap';

const NoDataModal = ({showModal, hideModal, 
  id,  message}) => {
    return (
      <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>No Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='alert alert-danger'>{message}</div>
        </Modal.Body>
      </Modal>
    )
  }

export default NoDataModal;
