import React from 'react';
import './AdminTable.css';

const AdminTable = ({userList,
                      handleEdit,
                      handleResetPassword,
                      handleDelete
                    }) => {

  return (
    <div className="table">
      {userList.length === 0 ? (
        <p>No users to display.</p>
      ) : (
        <table>
          <thead>
            <tr>
              {Object.keys(userList[0]).map((key) => (
                  <th key={key}>{key}</th>
              ))}
              <th>Password</th>
              <th>Update</th>
              <th>Delete</th>
            </tr>
          </thead>
            <tbody>
              {userList.map((user, index) => (
                <tr key={index}>
                  {Object.values(user).map((value, id) => (
                  <td key={id}>
                    {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value}
                  </td>
                  ))}
                  <td>
                    <button className="favorite edit bgColor" onClick={()=>handleResetPassword(user)}>Reset</button>
                  </td>
                  <td>
                    <button className="favorite edit bgColor" onClick={()=>handleEdit(user)}>Edit</button>
                  </td>
                  <td>
                    <button className="favorite styled bgColor" onClick={()=>handleDelete(user)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
        </table>
      )}
    </div>
    );
  };

export default AdminTable;