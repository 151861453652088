import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PlayerIdSearchList from "../Search/PlayerIdSearchList";
import { Button } from "react-bootstrap";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const scrole = {
  overflow: 'auto',
  maxHeight: 400,
  marginLeft: 50,
  marginRight: 50,
  marginBottom: 30,
}

const RoundModal = props => {

  const {rounds} = props;
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(()=> {
    if(rounds.length >= 1) {
      setOpen(true);
    }
  }, [rounds]);
  
  return (
    <Modal open={open}  onClose={handleClose}
      aria-labelledby="modal-modal-title" 
      aria-describedby="modal-modal-description"
    >
    <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      Internal Wager Rounds & Replay URL
      <span className="close" onClick={handleClose}>&times;</span>
    </Typography> 
    <Typography style={scrole} id="modal-modal-description" sx={{ mt: 3 }}>
    <PlayerIdSearchList players ={rounds}/>
    </Typography>
    <Button onClick={handleClose}>Close</Button>
  </Box> 
  </Modal>
  )
}

export default RoundModal;