import React, { useEffect, useState} from 'react';
import { PARTNERS_LIST } from "../../../api/stuckGameHandler";
import axios from 'axios';
import Labels from './Labels';
import { useAuth } from '../../../hooks/AuthContext';
import { useNavigate } from "react-router-dom";
import './CustomPopUp.css';

const CustomPopUp  = ({props}) => {
  const navigate = useNavigate();
  const status = [{"status":"disabled"},{"status":"enabled"}]

  const [selectedJobName, setSelectedJobName] = useState('');
  const [selectedHoursDate, setSelectedHoursDate] = useState('');
  const [selectedMinuteDate, setSelectedMinuteDate] = useState('');
  const [selectedPartner, setSelectedPartner] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedDays, setSelectedDays] = useState('');

  const [partnerList, setPartnerList] = useState([]);
  const { headers, reset } = useAuth();
    
  const isButtonEnabled = () => {
    if(props.selectedValues.titalHead === "New"){
      return (
        selectedJobName !== '' &&
        selectedDate !== '' &&
        selectedHoursDate !== '' &&
        selectedHoursDate !== 'HH' &&
        selectedMinuteDate !== 'MM' &&
        selectedMinuteDate !== '' &&
        selectedPartner !== '' &&
        selectedStatus !== '' &&
        selectedDays !== ''
      );
    } else{
      return (props.selectedValues.titalHead !== "New")
    }
  };

  useEffect(() => {
    axios.get(PARTNERS_LIST, headers)
    .then((response) => {
      setPartnerList(response.data.body);
    })
    .catch((error) => {
      if((error.response !== undefined) && (error.response.status === 500)){
        reset();
        navigate('/');
        window.flash("Session expired","error");
      }else{
        console.log('Error fetching user list:', error);
      } 
    });
  }, []); 

  const handleJobNameChange = (event) => {
    props.selectedValues.jobName = event.target.value
    setSelectedJobName(event.target.value);
  }

  const handleTimeChange = (event) => {
    let value = event.target.value;
    if(event.target.id === "hours"){
      if (value > 23 ) {
        value = 23;
      }
      props.selectedValues.hour = value;
      setSelectedHoursDate(value);
    }
    else{
      if (value > 59 ) {
        value = 59;
      }
      props.selectedValues.minute = value;
      setSelectedMinuteDate(value);
    }
  }

  const handleDateChange = (event) => {
    props.selectedValues.date = event.target.value
    setSelectedDate(event.target.value);
  }

  const handlePartnerChange = (partnerName) => {
      let foundId = '';
      for (const item of partnerList) {
        if (item.name === partnerName) {
          foundId = item.siteId;
          break;
        }
      }
      props.selectedValues.partnerId = foundId;
      props.selectedValues.name = partnerName;
    setSelectedPartner(partnerName);
  }

  const handleStatusChange = (event) => {
    props.selectedValues.jobStatus = event.target.value
    setSelectedStatus(event.target.value);
  }

  const handleNumberChange = (event) => {
    props.selectedValues.days = event.target.value
    setSelectedDays(event.target.value);
  }

  const setAllValues = () => {
    const row = {
      jobName : props.selectedValues.jobName,
      date: props.selectedValues.date,
      hour: props.selectedValues.hour,
      minute: props.selectedValues.minute,
      partnerId: props.selectedValues.partnerId,
      jobStatus: props.selectedValues.jobStatus,
      days: props.selectedValues.days
    }
    props.createJob(row);
    cleanStates();
  }

  const cleanStates = () => {
    setSelectedJobName('');
    setSelectedHoursDate('');
    setSelectedMinuteDate('');
    setSelectedPartner('');
    setSelectedDate('');
    setSelectedStatus(null);
    setSelectedDays('');
  }

  const closeModal = () => {
    cleanStates();
    props.closeModal();
  }

  return (
    <div className="App"> 
      {props.isModalOpen && (
        <div className="modal">
          <div className="modalContent">
            <span className="close" onClick={closeModal}>&times;</span>
            <h2>{props.selectedValues.titalHead} Job</h2>
            <div className="alert alert-light">
              <div className="box-content" >
                <div className='subDiv'>
                  <Labels/>
                  <div>
                    <div>
                      <labal>: </labal>
                          <label className='secons-div-labal'>
                          <input
                            class="form-control"
                              onChange={handleJobNameChange}
                              value={props.selectedValues.jobName} required>
                            </input>
                        </label>
                    </div>
                    <div>
                      <labal>: </labal>
                        <label htmlFor="dateInput" className='secons-div-labal'>
                          <input 
                            className="form-control"
                            type="date"
                            value={props.selectedValues.date}
                            onChange={handleDateChange}
                            required/>
                        </label>
                        <label className='secons-div-labal'>
                          <div className='time'>
                            <input
                              type="number"
                              id="hours"
                              placeholder='HH'
                              min="00"
                              max="24"
                              value={props.selectedValues.hour}
                              onChange={handleTimeChange}
                            ></input>
                            <labal id="time-colume"> : </labal>
                            <input
                              type="number"
                              id="minute"
                              placeholder='MM'
                              min="0"
                              max="59"
                              value={props.selectedValues.minute}
                              onChange={handleTimeChange}
                            ></input>
                            <p className='timeNote'>
                              Please set time as per UTC TimeZone.
                            </p>
                            </div>
                        </label>
                    </div>
                    <div>
                      <labal>: </labal>
                      <label className='secons-div-labal'>
                        {props.selectedEnablePartner && (
                        <select
                          className="form-control"
                          value={selectedPartner}
                          onChange={(e) => handlePartnerChange(e.target.value)} required>
                            <option value=""> -- Select Partner --</option>
                            <option value='0'> -- All Partners -- </option>
                            {partnerList.map((Partner) => (
                              <option key={Partner.id} value={Partner.name}>{Partner.code}</option>
                            ))}
                        </select>
                        )}
                        {props.partnerIdStatus && (<h6>{props.selectedValues.partnerId}</h6>)}
                      </label>
                    </div>
                    <div>
                      <labal>: </labal>
                      <label className='secons-div-labal'>
                      <select
                        class="form-control"
                        value={props.selectedValues.jobStatus}
                        onChange={handleStatusChange} required>
                        <option>-- Select Job Status --</option>
                        {status.map((hour) => (
                          <option>{hour.status}</option>
                        ))}
                      </select>
                      </label>
                    </div>
                    <div>
                      <labal>: </labal>
                        <label className='secons-div-labal'>
                        <input class="form-control" 
                        type='number'  min='0'
                        value={props.selectedValues.days}
                        onChange={handleNumberChange} required>
                        </input>
                      </label>
                    </div>
                    <div>
                      <p className='note'>
                        Note:  To exclude job for the partner. Please set No. of old days to 0. (no job will run for the partner)
                      </p>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
              <div>          
                {!props.partnerIdStatus && (
                   <p className='note'>
                   Note: All fields are mandatory.
                 </p>
                )}
                <button
                  class="favorite styled bgColor"
                  type="button"
                  onClick={closeModal}>
                  Cancel
                </button>
                <button
                  class="favorite newjob"
                  disabled={!isButtonEnabled()}
                  onClick={setAllValues}>
                  {props.selectedValues.buttonName}
                </button>
              </div>
           </div> 
        </div>
      )}
    </div>
  );
}

export default CustomPopUp;