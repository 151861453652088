import React from 'react';
import PlayerIdSearchList from './PlayerIdSearchList';

function PlayersList(props) {
  const {
    players,
    section,
    showSessionTab,
    getWalletSessionId,
    getWalletSession,
  } = props;

  if (players.body && players.body.length) {
    return (
      <PlayerIdSearchList
        section={section}
        players={players.body}
        showSessionTab={showSessionTab}
        getWalletSessionId={getWalletSessionId}
        getWalletSession={getWalletSession}
      />
    );
  }
}

export default PlayersList;
