import { useState } from "react";
import RadioButton from "./RadioButton";
import SearchBar from "./SearchBar";


export default function Search() {
  const [option, setoptions] = useState('byPlayerId');
  
  //This callback use to set select options
  const optionCallback = (selectedOption) => {
    setoptions(selectedOption);
  }
  return (
  <>
  <div className="alert alert-light">
    <RadioButton optionCallback={optionCallback}/>
    <SearchBar option={option}/>
  </div>
  </> 
  );
}