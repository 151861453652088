import React from 'react';
import './AllGames.css';

const AllGames = ({ props }) => {
  const currentDate = new Date().toISOString().split('T')[0];

  return (
    <div>
      <div>
        <label>
          <h6>Game :</h6>
          <select className="form-control" value={props.selectedgame} 
            onChange={(e) => props.setSelectedgame(e.target.value)}>
            <option value=''> All Games</option>
              {props.game && props.game
                .sort((game1, game2) => game1.gameName.localeCompare(game2.gameName)) // Sort the games by Name
                .map((game) => (
                  <option key={game.id} value={game.productId}>
                    {game.gameName}
                  </option>
                ))}
          </select>
        </label>
        <label>
          <h6>Partner :</h6>
          <select className="form-control" value={props.selectedPartner} 
          onChange={(e) => props.setSelectedPartner(e.target.value)}>
            <option value=''> All Partner</option>
            {props.partner && props.partner.map((Partner) => (
              <option key={Partner.id} value={Partner.siteId}>{Partner.name}</option>
            ))}
          </select>
        </label>
        <label htmlFor="dateInput">
          <h6>Start Date :</h6>
          <input
            className="form-control"
            type="date"
            max={currentDate}
            value={props.selectedStartDate}
            onChange={props.handleStartDateChange}
          />
        </label>
        <label htmlFor="dateInput">
          <h6>End Date :</h6>
          <input
            className="form-control"
            type="date"
            max={currentDate}
            value={props.selectedEndDate}
            onChange={props.handleEndDateChange}
          />
        </label>
      </div>
      <button
        className="btn btn-primary"
        id="findGameButton"
        disabled={(props.findGameButtonEnabled())} 
        onClick={props.handleIncompleteGame}>
        Find Game
      </button>
    </div>
  );
};

export default AllGames;
