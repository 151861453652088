import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { USER_LIST, CREATE_USER, UPDATE_USER, RESET_PASSWORD, DELETE_USER }from '../../api/adminTab';
import { useAuth } from '../../hooks/AuthContext';
import Popup from './Popup';
import AdminTable from './AdminTable';
import LoadingSpinner from '../helper/spinner/LoadingSpinner';
import './SuperAdminPanel.css';

const SuperAdminPanel = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfiramationBoxOpen, setIsConfiramationBoxOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState({
    name: '',
    password: '',
    email: '',
    role: '',
  });
  const [userList, setUserList] = useState('');
  const [delectUserPayload, setDelectUserPayload] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { headers, reset } = useAuth();
  const navigate = useNavigate();

  function UnauthorisedAccess(error) {
    if (error.response && error.response.status === 500) {
      reset();
      navigate('/');
      window.flash('Session expired', 'error');
    } else {
      console.log('Error fetching API', error);
    }
  }

  const getAllUserList = () => {
    axios.get(USER_LIST, headers)
      .then((response) => {
        setUserList(response.data.body);
        setIsLoading(false);
      })
      .catch((error) => {
        UnauthorisedAccess(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getAllUserList();
  }, []);

  const apiCall = (URL, payload) => {
    axios.post(URL, payload, headers)
      .then((response) => {
        if (response.data.success) {
          window.flash(response.data.message, 'success');
          getAllUserList();
        } else {
          window.flash(response.data.message, 'error');
        }
      })
      .catch((error) => {
        UnauthorisedAccess(error);
      });
  };

  const handleSubmit = (payload, apiType) => {
    let URL;
    switch (apiType) {
      case 'create':
        URL = CREATE_USER;
        break;
      case 'edit':
        URL = UPDATE_USER;
        break;
      default:
        URL = RESET_PASSWORD;
    }
    apiCall(URL, payload);
    setIsModalOpen(false);
  };

  const deleteUser = () => {
    const URL = DELETE_USER;
    const usernameValue = delectUserPayload.username;
    const apiUrl = URL + `${encodeURIComponent(usernameValue)}`;
    apiCall(apiUrl, headers);
    setIsConfiramationBoxOpen(false);
  };

  const openModal = (event) => {
    const addHeader = {
      Header: event.target.value,
    };
    setSelectedValues(addHeader);
    setIsModalOpen(true);
  };

  const handleEdit = (userData) => {
    const addHeader = {
      Header: 'edit',
      username: userData.username,
      name: userData.name,
      roleId: userData.roleId,
    };
    setSelectedValues(addHeader);
    setIsModalOpen(true);
  };

  const handleResetPassword = (userData) => {
    const addHeader = {
      Header: 'resetPassword',
      username: userData.username,
      password: '',
    };
    setSelectedValues(addHeader);
    setIsModalOpen(true);
  };

  const handleDelete = (user) => {
    setIsConfiramationBoxOpen(true);
    setDelectUserPayload(user);
  };

  return (
    <div className="alert alert-light">
      <div className="alert alert-light" id="create-admin">
        <h5 className="create-admin-tab">Create New User</h5>
        <div>
          <button
            className="favorite newjob"
            value="create"
            onClick={openModal}
          >
            Create User
          </button>
          <Popup
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            setSelectedValues={setSelectedValues}
            selectedValues={selectedValues}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
      <div>
        <AdminTable
          userList={userList}
          handleEdit={handleEdit}
          handleResetPassword={handleResetPassword}
          handleDelete={handleDelete}
        />
      </div>
      {isConfiramationBoxOpen && (
        <div className="modal">
          <div className="confermationbox-content">
            <span
              className="close"
              onClick={() => setIsConfiramationBoxOpen(false)}
            >
              &times;
            </span>
            <h3 className="configrationBoxh3"> Delete User</h3>
            <div className="alert alert-light">
              <h6 className="configrationBoxh6">Are you sure.</h6>
              <h6 className="configrationBoxh6">You want to delete the user.</h6>
            </div>
            <button
              className="favorite styled bgColor"
              type="button"
              onClick={() => setIsConfiramationBoxOpen(false)}
            >
              Cancel
            </button>
            <button
              className="favorite newjob"
              type="button"
              onClick={deleteUser}
            >
              Confirm
            </button>
          </div>
        </div>
      )}
      {isLoading && (<LoadingSpinner/>) }
    </div>
  );
};

export default SuperAdminPanel;
