import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { useCookies } from 'react-cookie';
import jwtDecode from 'jwt-decode';

const AuthContext = createContext({
  auth: null,
  setAuth: () => {},
  user: null,
  token: null,
  headers: null,
  role: {},
});

export const useAuth = () => useContext(AuthContext);

function AuthProvider({ children }) {
  const [auth, setAuth] = useState(null);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [cookies, , removeCookie] = useCookies(['token']);
  const [headers, setHeaders] = useState(null);
  const [roles, setRoles] = useState({
    ROLE_SUPER_ADMIN: false,
    ROLE_ADMIN: false,
    ROLE_NORMAL: false,
  });

  const reset = () => {
    removeCookie('token');
    setAuth(false);
    setRoles({});
  };

  useEffect(() => {
    const isAuth = async () => {
      try {
        // Decode jwt token to get user information
        const user = await jwtDecode(cookies.token);
        // Set user information for further use
        const userDetails = {
          username: user.sub,
          role: user.role,
        };
        const newRoles = roles;
        newRoles[user.role] = true;
        setRoles(newRoles);
        setUser(userDetails);
        // If user details set setup cookie and other required variables
        if (userDetails) {
          setToken(cookies.token);
          setAuth(true);
          setHeaders({
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + cookies.token
            },
          });
        }
      } catch (err) {
        setUser(null);
      }
    };
    isAuth();
  }, [auth, cookies, token, roles]);

  return (
    <AuthContext.Provider value={
        { auth, setAuth, user, token, headers, roles, setRoles, reset}
      }
    >
      {children}
    </AuthContext.Provider>
  );
}
export default AuthProvider;
