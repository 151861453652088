import React from 'react';
import { useAuth } from '../../../hooks/AuthContext';
import './Table.css';

const Table = ({ props }) => {
  const {roles} = useAuth();

  const isCloseGameButtonEnabled = () => 
    (roles.ROLE_SUPER_ADMIN || roles.ROLE_ADMIN) &&
      (props.selectedOpenWager.length !== 0)

  return (
    <div className="alert alert-light"> 
        <div className="table">
          <h5>Open Games Details :</h5>
            <table>
            <thead>
              <tr>
                <th>
                <label htmlFor="checkbox_selectAll">SelectAll</label>
                  <input 
                    value="tableColumn"
                    className="center-block"
                    type="checkbox"
                    id="checkbox_selectAll"
                    checked={props.selectAll}
                    onChange={props.handleSelectAll}
                  />
                </th>
                <th>Game Id</th>
                <th>Game Name</th>
                <th>Player Id</th>
                <th>Wager Round Id</th>
                <th>Session Id</th>
                <th>Session Reference</th>
                <th>Wager Transaction Id</th>
                <th>Wager Amount</th>
                <th>Game State</th>
                <th>Started On</th>
                <th>Close Status</th>
                <th>Replay URL</th>
              </tr>
            </thead>
             <tbody>
              {props.findGameResponse.map((row, index) => (
                <tr key={index}>
                  <td>
                    <input 
                    value="tableRow"
                    className="center-block"
                    checked={props.selectedOpenWager.some((r) => r.glssessionId === row.glssessionId)}
                    type="checkbox" 
                    id={`checkbox_${row.glssessionId}`} 
                    onChange={(event) => props.handleRowSelect(event, row)}
                    />
                    <label htmlFor={`checkbox_${row.glssessionId}`}></label>
                  </td>
                  <td>{row.gameId}</td>
                  <td>{row.gameName}</td>
                  <td>{row.playerId}</td>
                  <td>{row.glswagerId}</td>
                  <td>{row.glssessionId}</td>
                  <td>{row.sessionReference}</td>
                  <td>{row.wagerReference}</td>
                  <td>{row.wagerInFaceValue}</td>
                  <td>{row.wagerStatus}</td>
                  <td>{props.formatDate(row.startTime)}</td>
                  <td>{row.resolveStatus}</td>
                  <td>
                    <a target="_blank" rel="noopener noreferrer">
                    <button disabled="true" 
                     className="btn btn-lg btn-primary" id='replayButton'>
                      Replay
                    </button> 
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
         </table> 
        </div>  
        <div>
          <button class="btn btn-lg btn-primary" 
            value="Close" 
            disabled={!isCloseGameButtonEnabled()} 
            onClick={props.confirmationBox} >
              Normal Close
          </button>
          <button class="btn btn-lg btn-primary" 
            value="ForceClose" 
            id="forceCloseButton" 
            disabled={!isCloseGameButtonEnabled()} 
            onClick={props.confirmationBox} >
              Force Close 
          </button>
        </div>   
        {props.isModalOpen && (
          <div className="modal">
            <div className="confermationbox-content">
              <span
                className="close"
                onClick={props.closemultiplegames}>
                  &times;
              </span>
              <h3 className='configrationBoxh3'>{props.closeGameType} Game</h3>
              <div className="alert alert-light">
                <h6 className='configrationBoxh6'>Are you sure.</h6>
                <h6 className='configrationBoxh6'>You want to {props.closeGameType} the Game.</h6>
              </div>
              <button
                class="favorite styled bgColor"
                type="button"
                onClick={props.closeConfirmationBox}>
                  Cancel
              </button>
              <button
                class="favorite newjob"
                type="button"
                onClick={props.closemultiplegames} >
                Confirm
              </button>
            </div>
          </div>
        )}
      </div>
  );
};

export default Table;
